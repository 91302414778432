import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import BookingImg from '../assets/images/IconsNew/booking.png'
import SummaryImg from '../assets/images/IconsNew/info.png'
import PassengerImg from '../assets/images/IconsNew/passenger.png'
import ReceiptImg from '../assets/images/IconsNew/payment.png'
import LoginImg from '../assets/images/IconsNew/user.png'
import DoneImg from '../assets/images/IconsNew/done.png'
import DoneMobileImg from '../assets/images/IconsNew/donemobile.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, StepButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {

      //borderColor: '#0A2638',
      //borderTopStyle: 'solid', 
      backgroundImage: `url(${DoneImg})`,
      width: '100%',
      minHeight: 40,
      minWidth: 220,
      borderTop: 'none',
      backgroundRepeat: 'no-repeat',
      marginTop: -6
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      //borderColor: '#0A2638',
      //borderTopStyle: 'solid', 
      backgroundImage: `url(${DoneImg})`,
      width: '100%',
      minHeight: 40,
      minWidth: 220,
      borderTop: 'none',
      backgroundRepeat: 'no-repeat',
      marginTop: -6
    },
  },
  [`& .${stepConnectorClasses.line}`]: {

    border: 3,
    marginLeft: '15%',
    //marginRight:10,
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#c67b15',
    borderTopStyle: 'dashed',
    maxWidth: '70%',
    marginTop: -6
  },
}));

const ColorlibConnectorMobile = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `url(${DoneMobileImg})`,
      width: '100%',
      minHeight: 40,
      minWidth: 200,
      borderTop: 'none',
      backgroundRepeat: 'no-repeat',
      marginLeft: '-15%'
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `url(${DoneMobileImg})`,
      width: '100%',
      minHeight: 40,
      minWidth: 200,
      borderTop: 'none',
      backgroundRepeat: 'no-repeat',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {

    border: 3,
    marginLeft: '15%',
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#c67b15',
    borderTopStyle: 'dashed',

  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  //backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  //color: '#fff',
  width: 50,
  height: 5,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

}));



function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <img className='workflow_icon' src={BookingImg} style={{ }} />,
    2: <img className='workflow_icon' src={SummaryImg} style={{ }} />,
    3: <img className='workflow_icon' src={PassengerImg} style={{  }} />,
    4: <img className='workflow_icon' src={ReceiptImg} style={{  }} />,
    5: <CheckCircleIcon className='workflow_icon' style={{  }} />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}


function ColorlibStepIconGuest(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <img className='workflow_icon' src={BookingImg} style={{  }} />,
    2: <img className='workflow_icon' src={SummaryImg} style={{  }} />,
    3: <img className='workflow_icon' src={PassengerImg} style={{  }} />,
    4: <img className='workflow_icon' src={LoginImg} style={{  }} />,
    5: <img className='workflow_icon' src={ReceiptImg} style={{  }} />,
    6: <CheckCircleIcon className='workflow_icon' style={{  }} />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function ColorlibStepIconGuestMobile(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <img className='workflow_icon' src={BookingImg} style={{  }} />,
    2: <img className='workflow_icon' src={SummaryImg} style={{  }} />,
    3: <img className='workflow_icon' src={PassengerImg} style={{  }} />,
    4: <img className='workflow_icon' src={LoginImg} style={{  }} />,
    5: <img className='workflow_icon' src={ReceiptImg} style={{  }} />,
    6: <CheckCircleIcon className='workflow_icon' style={{  }} />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function ColorlibStepIconMobile(props) {
  const { active, completed, className } = props;

  const icons = {

    1: <img className='workflow_icon' src={BookingImg} style={{  }} />,
    2: <img className='workflow_icon' src={SummaryImg} style={{  }} />,
    3: <img className='workflow_icon' src={PassengerImg} style={{  }} />,
    4: <img className='workflow_icon' src={LoginImg} style={{  }} />,
    5: <img className='workflow_icon' src={ReceiptImg} style={{ }} />,
    6: <CheckCircleIcon className='workflow_icon' style={{  }} />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};


export default function WorkFlow({ activeStep, mode }) {
  const { user } = useSelector(state => state.auth)

  let steps = ['Search', 'Choose', 'Book', 'Payment', 'Confirm'];
  const navigate = useNavigate()
  const handleStep = (step) => () => {
    if (activeStep !== step && step < activeStep && activeStep <= 2) {
      switch (step) {

        case 0:
          navigate("/newbooking");
          break;

        case 1:
          navigate("/summary");
          break;

        case 2:
          navigate("/passengerdetails");
          break;

        case 3:
          navigate("/payment");
          break;

        case 4:
          navigate("/done");
          break;


        default:
          navigate("/newbooking");
      }
    }

  };

  return (
    <div className='workflow_holder'>
    <Stack className='workflow_box' sx={{ width: '100%', display: 'flex', alignItems: 'center' }} spacing={4} >
      <Box className="workflow_box_inner" display={{ xs: 'none', md: 'block' }} sx={{ paddingTop: 3, width: '45%' }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}  >
          {steps.map((label, index) => {
            return (
              <Step key={label} style={{  }}>
                {(!user) &&
                  <StepButton color="inherit" style={{ cursor: activeStep < 3 ? 'pointer' :  'default' }} onClick={handleStep(index)}>
                    <StepLabel StepIconComponent={ColorlibStepIcon} style={{ }}>{label}</StepLabel>
                  </StepButton>}
                {(user?.role === "agent" || user?.role === "customer" || user?.role === "corporate") &&
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    <StepLabel StepIconComponent={ColorlibStepIcon} style={{  }}>{label}</StepLabel>
                  </StepButton>}
              </Step>
            )
          })}
        </Stepper>
      </Box>

      {/* Mobile View */}
      {/* <Box display={{ xs: 'block', md: 'none' }} sx={{overflowX:'hidden' ,overflowY:'hidden'}}> 
      <Stepper  alternativeLabel  activeStep={activeStep} connector={<ColorlibConnectorMobile /> } >
        {steps.map((label, index) => {
          return(
            <Step key={label} style={{ display: 'flex' }}>
              {(!user) &&
              <StepButton color="inherit" onClick={handleStep(index)}>
                  <StepLabel StepIconComponent={ColorlibStepIconMobile} style={{ display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center',fontFamily:'12px',gap:5 }}>{label}</StepLabel>
              </StepButton>}
              {(user?.role === "agent" || user?.role === "customer" || user?.role === "corporate") &&
              <StepButton color="inherit" onClick={handleStep(index)}>
                  <StepLabel StepIconComponent={ColorlibStepIconMobile} style={{ display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center',fontFamily:'12px',gap:5}}>{label}</StepLabel>
              </StepButton>}
            </Step>
          )
        })}
      </Stepper>
      </Box> */}
    </Stack>
    </div>
  );
}
