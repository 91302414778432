import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import TermsConditionsContent from './TermsConditionsContent';

const TermsConditions = () => {

    return (
        <Layout head={"Terms & Conditions"}>
            <TermsConditionsContent/>   
        </Layout>
    )
}

export default TermsConditions
