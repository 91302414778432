export const RESET_USER = 'RESET_USER';
export const LOADING = 'LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const RE_LOGIN = 'RE_LOGIN';

export const RESET_AUTH = 'RESET_AUTH';

export const CLEAR_USER = 'CLEAR_USER';

export const RESET_ERROR = 'RESET_ERROR';

export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const SET_PREVIOUS_ROUTE = 'SET_PREVIOUS_ROUTE';
export const CLEAR_PREVIOUS_ROUTE = 'CLEAR_PREVIOUS_ROUTE';

export const SUB_USERS_SUCCESS = 'SUB_USERS_SUCCESS';
export const SUB_USERS_FAIL = 'SUB_USERS_FAIL';

export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_FAIL = 'NEW_USER_FAIL';

export const GUEST_LOGIN_SUCCESS = 'GUEST_LOGIN_SUCCESS'

export const PARTNER_MARKUPS = "PARTNER_MARKUPS";

export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_FAIL = 'ROLE_UPDATE_FAIL';




