import React, { useState, useEffect } from 'react'
import BorderBox from '../../components/BorderBox'
import { FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from '@mui/material'
import InputField from '../../components/InputField'
import DateInput from '../../components/DateInput'

const DepartureDetails = ({ bookingData, control, errors, setValue, locationFrom, locationTo, departure, setDeparture }) => {

   
    if(locationTo?.type === "airport"){
        return(
            <BorderBox title={"Departure Details"}>
                        <Grid container spacing={.5}>
                            <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                <RadioGroup row style={{ paddingTop: 5 }} >
                                    <FormControlLabel
                                        value="international"
                                        control={<Radio
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setDeparture('international')
                                                }
                                            }}
                                            checked={departure === "international"}
                                        //name="arival" {...register("arival")} value="international"   
                                        />}
                                        label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                                    />
                                    {locationFrom?.countryCode !== "SG" && <FormControlLabel
                                        value="domestic"
                                        control={<Radio
                                            name="arival"
                                            //{...register("arival")}  
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setDeparture('domestic')
                                                }
                                            }}
                                            checked={departure === "domestic"}
                                        />}
                                        label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                                    />}
                                </RadioGroup>
                                <span style={{ fontSize: 10, color: 'red' }}>{errors?.departure?.message}</span>
                            </Grid>
                            <Grid xs={12} md={1.5} item sx={{ flexGrow: 1 }}>
                                <InputField
                                    fieldLabel="Flight No."
                                    fieldName="departure_flight_no"
                                    control={control}
                                    error={errors?.departure_flight_no}
                                />
                            </Grid> 
                            <Grid xs={12} md={3} sx={{ flexGrow: 1, mt: .5 }}>
                                <DateInput
                                    fieldLabel="Flight Date & Time"
                                    minDate={new Date()}
                                    //maxDate={moment(booking?.returndate).toDate()}
                                    fieldName="departure_flight_date"
                                    control={control}
                                    fieldType="datetime"
                                    error={errors?.departure_flight_date}
                                    changeDate={(value) => setValue("departure_flight_date", value)}
                                />
                            </Grid>
                        </Grid>
                    </BorderBox>
        )
    }
    else{
        return null
    }

    

    
}

export default DepartureDetails