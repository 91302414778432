import { PROFILE_LIST_SUCCESS, UPDATE_PROFILE_SUCCESS } from "../constants/profileConstants";
import { 
    LOADING, LOGIN_FAIL, LOGIN_SUCCESS, RESET_AUTH, RE_LOGIN, 
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    RESET_ERROR,
    SET_PREVIOUS_ROUTE,
    CLEAR_PREVIOUS_ROUTE,
    SUB_USERS_SUCCESS,
    SUB_USERS_FAIL,
    NEW_USER_SUCCESS,
    NEW_USER_FAIL,
    GUEST_LOGIN_SUCCESS,
    PARTNER_MARKUPS,
    ROLE_UPDATE_SUCCESS,
    ROLE_UPDATE_FAIL
    
} from "../constants/userConstants";

export  const authReducer = (state = { error: null }, action) => {
    switch(action.type){

        case LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case RESET_AUTH:
            return {
                loading: null,
                isAuthenticated: null,
                error: null
            }

        case RESET_ERROR:
            return{
                ...state,
                error: null,
                registerSuccess:null,
                newUserSuccess: null,
                roleUpdate: null
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                loading: false
            }

        case PROFILE_LIST_SUCCESS:
            return{
                ...state,
                user: action.payload
            }

        case GUEST_LOGIN_SUCCESS:
            return{
                ...state,
                user: action.payload,
                loading: false
            }

        case UPDATE_PROFILE_SUCCESS:
            return{
                ...state,
                user: action.payload
            }

        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                error : action.payload?.message,
                loading: false
            }

        case RE_LOGIN:
            return {
                ...state,
                user: null,
                isAuthenticated : false,
                loading: false
            }
        case USER_REGISTER_SUCCESS:
            return {
                ...state,
                registerSuccess: true
            }
        
        case USER_REGISTER_FAIL:
        case SUB_USERS_FAIL:
        case NEW_USER_FAIL:
        case ROLE_UPDATE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case SET_PREVIOUS_ROUTE:
            return{
                ...state,
                previousRoute: action.payload
            }

        case CLEAR_PREVIOUS_ROUTE:
            return{
                ...state,
                previousRoute: null
            }

        case SUB_USERS_SUCCESS:
            return{
                ...state,
                subUsers: action.payload
            }

        case NEW_USER_SUCCESS:
            return{
                ...state,
                newUserSuccess: true
            }

        case PARTNER_MARKUPS:
            return{
                ...state,
                myTransfer: action.payload?.myTransfer,
                gett: action?.payload?.gett,
                gnet: action?.payload?.gnet,
                additionalStops: parseFloat(action.payload.additionalStops)
            }

        case ROLE_UPDATE_SUCCESS:
            return{
                ...state,
                roleUpdate: true
            }

        default: 
            return state;
    }
}
