import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import ContactContent from './ContactContent';

const ContactUs = () => {

    return (
        <Layout head={"Contact Us"}>
            <ContactContent/>   
        </Layout>
    )
}

export default ContactUs
