

import { calculateExtra } from "./extraCharges";
import { isArray } from 'lodash'

export const pricePlan = (item, bookingData, additionalStops, user, markupLists, service) => {


    let agentmarkup = 0;
    let adminMarkup = 0
    let tax = 0;
    let total = 0;
    let additionalStop = 0;
    let adminDiscount = 0;
    let services = item?.services?.map(ser => ser?.id)
    let serviceCharge = 0;
    let extraSeat = item?.extraSeatRate ?  Math.ceil(item?.extraSeatRate) : 0
    serviceCharge = item?.services?.length > 0 ? item?.services?.reduce(function (acc, obj) { return acc + Math.ceil(obj.price); }, 0) : 0;


    let newAddOns = item?.addOnServices?.map(addon => {
        return {
            id: addon?.id,
            name: addon?.addonservice?.name,
            price: item?.day == 1 ? (parseFloat(bookingData?.duration)/24).toFixed(0) * addon?.rate : addon?.rate,
            checked: services?.includes(addon?.id) ? true : false
        }
    })

    let toll = item?.tollrate ? Math.ceil(item?.tollrate) : 0;

    if(additionalStops){
        additionalStop = isArray(bookingData?.stops) ? bookingData?.stops?.length * additionalStops : 0;
    }

    

    let rate = bookingData?.type === "hour" ? Math.ceil((parseFloat(item?.rate) * parseFloat(bookingData?.duration)) + additionalStop) : Math.ceil(item?.rate) + additionalStop

    
    let extras = calculateExtra(item, bookingData?.pickupdate, bookingData?.type, bookingData?.duration, parseFloat(item?.rate), 'price');
    let extraArray = calculateExtra(item, bookingData?.pickupdate, bookingData?.type, bookingData?.duration, parseFloat(item?.rate), 'array');


    if(user?.role === "agent"){
        if(markupLists?.type === "fixed"){
            agentmarkup = Math.ceil(markupLists?.value);
        }
        else{
            agentmarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(markupLists?.value))
        }
    }


    if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
        if (item?.markUpDetails[0]?.value_type === "Percentage") {
            adminMarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(item?.markUpDetails[0]?.value))
        }
        else {
            adminMarkup = Math.ceil(item?.markUpDetails[0]?.value)
        }
    }
    else if (item?.defaultMarkup) {
        adminMarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(item?.defaultMarkup?.value))
    }

    if(item?.discountData){
        let ddata = item?.discountData;
        if(ddata?.discount_type === "percentage"){
            let totalAmount = rate + adminMarkup + agentmarkup + toll + extras + extraSeat;
            adminDiscount = Math.ceil((totalAmount/100) * parseFloat(ddata?.discount))
        }
        else{
            adminDiscount += Math.ceil(ddata?.discount)
        }
    }



    tax = Math.ceil(((rate + adminMarkup + agentmarkup + extras + toll - adminDiscount + serviceCharge + extraSeat) / 100) * parseFloat(item?.tax?.value))

    total = Math.ceil(rate + adminMarkup + agentmarkup + tax + extras + toll - adminDiscount + serviceCharge + extraSeat);


    // --------------------return start--------------------


    // if (bookingData?.bookingType === "return") {
    //     extras = calculateExtra(item, bookingData?.returndate, bookingData?.type, bookingData?.duration, parseFloat(item?.rate), 'price');
    //     returnExtraArray = calculateExtra(item, bookingData?.returndate, bookingData?.type, bookingData?.duration, parseFloat(item?.rate), 'array');
    //     if(user?.role === "agent"){
    //         if(markupLists?.type === "fixed"){
    //             returnAgentMarkup = Math.ceil(markupLists?.value);
    //         }
    //         else{
    //             returnAgentMarkup = Math.ceil(((rate + returnExtra + toll + extraSeat) / 100) * parseFloat(markupLists?.value))
    //         }
    //     }

    //     if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
    //         if (item?.markUpDetails[0]?.value_type === "Percentage") {
    //             returnAdminMarkup = Math.ceil(((rate + returnExtra + toll + extraSeat) / 100) * parseFloat(item?.markUpDetails[0]?.value))
    //         }
    //         else {
    //             returnAdminMarkup = Math.ceil(item?.markUpDetails[0]?.value)
    //         }
    //     }
    //     else if (item?.defaultMarkup) {
    //         returnAdminMarkup = Math.ceil(((rate + returnExtra + toll + extraSeat) / 100) * parseFloat(item?.defaultMarkup?.value))
    //     }


    //     if(item?.discountData){
    //         let ddata = item?.discountData;
    //         if(ddata?.discount_type === "percentage"){
    //             let totalAmount = rate + returnAdminMarkup + returnAgentMarkup + toll + returnExtra + extraSeat;
    //             returnAdminDiscount = Math.ceil((totalAmount/100) * parseFloat(ddata?.discount))
    //         }
    //         else{
    //             returnAdminDiscount = Math.ceil(ddata?.discount)
    //         }
    //     }

    //     //returnTotalWithoutTax = Math.ceil(rate + returnAdminMarkup + returnAgentMarkup + returnExtra + toll - returnAdminDiscount + extraSeat);
    //     returnTax = Math.ceil(((returnTotalWithoutTax) / 100) * parseFloat(item?.tax?.value))

    //     returnTotal = Math.ceil(returnTotalWithoutTax + returnTax);
    // }


    // -----------------------------return end-------------------


    return {
        id: item?.id,
        baseRate: rate,
        adminMarkup,
        agentmarkup,
        extras,
        extraArray,
        toll,
        adminDiscount,
        serviceCharge,
        tax,
        total: total,
        vehicleType: item?.vehicledetails?.vehicle_type?.vehicle_type,
        newAddOns,
        vehicleTypeId: item?.vehicledetails?.vehicle_type?.id,
        vehicleId: item?.vehicledetails?.veh_details?.vehicle_id,
        driverId: item?.vehicledetails?.veh_details?.driver_id,
        extraSeat
    }


    



}

