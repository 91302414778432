import React, { useCallback, useRef, useState } from 'react'
import { FormGroup, IconButton, InputAdornment } from '@mui/material';
import { TextField, Typography } from '@mui/material'
import { Controller } from "react-hook-form";
import { makeStyles } from '@mui/styles';
import { Image, Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
	noBorder: {
		borderRadius: 0,
		border: 0,
	},
	resize: {
		fontSize: 12,
	},
}));

const FileInput = ({ fieldName, fieldType, fieldLabel, placeholder, control, error, children, defaultValue, required, onChange }) => {

	const [showPass, setShowPass] = useState(false)
	const [values, setValue] = useState(null);


	const inputFile = useRef(null);
	const classes = useStyles();

	const onchangeRes = useCallback((e) => {
		onChange()
		setValue(e.target.files[0])
	}, [])

	return (
		<FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1 }}  >
			<Typography
				className='formText'
				sx={{ fontSize: '12px' }} >{fieldLabel}{required && <span style={{ color: 'red' }}>*</span>}</Typography>
			<Controller
				name={fieldName}
				control={control ? control : null}
				render={({ field }) => <div>
					<TextField
				
						size='small'
						// defaultValue={defaultValue}
						error={error?.message ? true : false}
						onClick={() => inputFile.current.click()}
						InputProps={{
							disableUnderline: true,
							endAdornment: <IconButton  style={{
								height: 30,
								borderRadius: 0,
								backgroundColor: '#c67b15'
							}} >
								<Image style={{ color: '#fff' }} />
							</IconButton>,

							classes: {
								input: classes.resize
							},
						}}
						{...field}
						value={values?.name}
						inputProps={{
							accept: "application/pdf",
							placeholder: "Upload a File"
						}}
						style={{ backgroundColor: '#fff', justifyContent: 'center', border: '1px solid #ccc', borderRadius: 4, paddingLeft: 5, height: '30px', width: '98.5%' }}

						onChange={null}
						variant='standard' className='formText'
						sx={{
							"& .MuiFilledInput-underline:before": {
								borderBottom: "none"
							},
							"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
								borderBottom: "none"
							},
						}}
						type={"file"}
						placeholder={"Choose Your File"}
					>{children}</TextField>

					<input
						ref={inputFile}
						type={"file"}
						accept="application/pdf"
						style={{ display: 'none' }}
						// onChange={onchangeRes}
					/>

				</div>

				}
			/>
			{error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}
		</FormGroup>
	)
}

export default FileInput
