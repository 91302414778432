import React, { useMemo } from 'react'
import BorderBox from '../../components/BorderBox'
import { Grid, MenuItem } from '@mui/material'
import InputField from '../../components/InputField'
import DropdownInput from '../../components/DropdownInput'
import PhoneInputs from '../../components/PhoneInputs'
import ImageInput from '../../components/ImageInput'
import countryList from 'react-select-country-list'
import { useDispatch, useSelector } from 'react-redux'
import customAxios from '../../CustomAxios'
import { LOADING } from '../../Redux/constants/userConstants'
import { BOOKING_INPUT } from '../../Redux/constants/bookingConstants'
import { toast } from 'react-toastify'
import { Image } from '@mui/icons-material';
import { Link } from 'react-router-dom'



const PassengersDetails = ({ control, errors, setValue, setArrival, getValues, data }) => {

    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)


    

    const options = useMemo(() => countryList().getData(), [])


    const uploadFiles = async (file) => {



        dispatch({
            type: LOADING,
            payload: true
        })

        try {
            let formData = new FormData();
            formData.append("itenary", file)
    
           const res =  await customAxios.post(`uploaditenary`, formData);
           if(res?.statusText === "OK"){
                setValue("itenary", res?.data?.data)
           }
           else{
            throw new Error("Network Error")
           }
        } catch (error) {
            toast.error(error)
        } finally{
            dispatch({
                type: LOADING,
                payload: false
            })
        }
       
    }



    return (
        <BorderBox title={"Passenger Details"}>
            <Grid container spacing={.5} >
                <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <InputField
                        fieldLabel="Passenger Name"
                        fieldName="passenger_name"
                        fieldType="text"
                        control={control}
                        error={errors.passenger_name}
                        required
                        marginTop={0}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <InputField
                        fieldLabel="Email Address (Booking Notification)"
                        fieldName="email"
                        control={control}
                        error={errors.email}
                        required
                    />
                </Grid>
                {data?.country && <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <DropdownInput
                        fieldLabel="Country"
                        fieldName="country"
                        control={control}
                        error={errors.country}
                        values={getValues("country")}
                        changeValue={(e) => {
                            setValue("country", e.target?.value);
                            if (e?.target?.value === "SG") {
                                setArrival('international')
                            }

                        }}
                    >
                        <MenuItem disabled value="" className='formText'>
                            Select
                        </MenuItem>
                        {options?.map(opt => (
                            <MenuItem className='formText' value={opt?.value}>{opt?.label}</MenuItem>
                        ))}
                    </DropdownInput>
                </Grid>}
                <Grid item xs={12} md={6} sx={{ flexGrow: 1 }} >
                    <PhoneInputs
                        fieldLabel="Mobile"
                        fieldName="mobile"
                        control={control}
                        error={errors.mobile}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                    <InputField
                        fieldLabel="Check-in Luggage"
                        fieldName="luggage"
                        fieldType={"number"}
                        min={1}
                        control={control}
                        error={errors.luggage}
                    //onChangedev={onChangedev}
                    />
                </Grid>
                <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                    <InputField
                        fieldLabel="Hand Carry"
                        fieldName="hand_carry"
                        fieldType={"number"}
                        min={1}
                        control={control}
                        error={errors.hand_carry}
                    />
                </Grid>

                
                {(user?.role === 'agent' || user?.role === 'corporate') ? <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <InputField
                        fieldLabel="Reference Number"
                        fieldName="reference_number"
                        fieldType={"text"}
                        control={control}
                        error={errors.reference_number}
                    />
                </Grid> : ''}
                {data?.type === "hour" && <Grid item xs={12} md={6} sx={{ flexGrow: 1, position:'relative' }}>
                    <ImageInput
                        fieldName="itenary"
                        fieldLabel="Itinerary"
                        // imageHelperText={"512 * 512"}
                        control={control}
                        error={errors.itenary}
                        type={'application/pdf'}
                        changeImage={(e) => uploadFiles(e)}
                        Icon={
                            <div className='uploadIcon'><Image  /></div>}
                    />
                    {data?.itenary && <Link to={data?.itenary} target='blank'><Image sx={{ position:'absolute', top: 25, right: -20, color:'red' }}  /></Link>}
                </Grid>}
            </Grid>
        </BorderBox>
    )
}

export default PassengersDetails