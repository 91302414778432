
import './App.css';
import { RouterProvider, createBrowserRouter} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from './Redux/actions/userActions';
import { LOGIN_SUCCESS, RESET_USER } from './Redux/constants/userConstants';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { LoadScript } from "@react-google-maps/api";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { guest, agent, corporate, customer } from './config/AppRoutes';
import { env } from './config/Constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const theme = createTheme({
    typography: {
        allVariants: {
          fontFamily: 'Nunito',
        },
    },
    components: {
        MuiFormLabel: {
          styleOverrides: {
            asterisk: {
              color: "#db3131",
              "&$error": {
                color: "#db3131",
              },
            },
          },
        },
    },
    direction: 'ltr',
});


function App() {

    const [loading, setLoading] = useState(true)
    const [router, setRouter] = useState(null);

    const { user, loading: apiLoading } = useSelector(state => state.auth)

    const [open, setOpen] = useState(false);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserToken())
        checkLogin()
    }, [])

    useEffect(() => {
        if(!user){
            setRouter(guest);
        }
        else{
            if(user.role === 'agent'){
                if(user?.parent_id > 0){
                    let routes = [];
                    if(user?.rolesinfo?.roles_json?.routes){
                        user?.rolesinfo?.roles_json?.routes?.map(rou => {
                            let finded = agent.find(ag => ag.path === rou?.path)
                            if(finded){
                                routes = [...routes, finded]
                            }
                        })
                        setRouter([...routes, ...customer])
                    }
                    else{
                        toast.error("Roles not defined. please check with admin")
                        dispatch({
                            type: RESET_USER
                        })
                        localStorage.removeItem("user")
						 localStorage.removeItem("usercurrency")
                        setRouter(guest);
                    }
                }
                else{
                    setRouter(agent)
                }
                
            }
            else if(user.role === 'corporate'){
                if(user?.parent_id > 0){
                    let routes = [];
                    if(user?.rolesinfo?.roles_json?.routes){
                        user?.rolesinfo?.roles_json?.routes?.map(rou => {
                            let finded = corporate.find(ag => ag.path === rou?.path)
                            if(finded){
                                routes = [...routes, finded]
                            }
                        })

                        setRouter([...routes, ...customer])
                    }
                    else{
                        toast.error("Roles not defined. please check with admin")
                        dispatch({
                            type: RESET_USER
                        })
                        localStorage.removeItem("user")
						 localStorage.removeItem("usercurrency")
                        setRouter(guest);
                    }
                }
                else{
                    setRouter(corporate)
                }
                //router = createBrowserRouter(corporate);
            }
            else if(user.role === "customer"){
                setRouter(customer);
                //router = createBrowserRouter(customer);
            }
            
        }
    }, [user])
    

    const checkLogin = async() => {
        
        let user  = await localStorage.getItem("user");
        
        if(user){
            let details = JSON.parse(user);
            //dispatch(getUserProfile(user?.id))
            if(details.role === 'agent'){
                setRouter(agent)
                //router = createBrowserRouter(agent);
            }
            else if(details.role === 'corporate'){
                setRouter(corporate)
                //router = createBrowserRouter(corporate);
            }
            else if(details.role === "customer"){
                setRouter(customer);
                //router = createBrowserRouter(customer);
            }
            dispatch({
                type: LOGIN_SUCCESS,
                payload: details
            })
            setLoading(false)
        }
        else{
            //router = createBrowserRouter(guest);
            setRouter(guest)
            setLoading(false)
        }
    }


    if(loading){
        return(
            <Box  sx={{ display: 'flex', flexDirection:'row-reverse', justifyContent:'center', alignItems:'center' }}>
                Loading...
            </Box>
        )
    }

    //const routers = createBrowserRouter(guest);


    return (
        <LoadScript googleMapsApiKey={ env === "client" ?  process.env.REACT_APP_GOOGLE_KEY_LIVE : process.env.REACT_APP_GOOGLE_KEY_DEV } libraries={["places"]}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <RouterProvider router={createBrowserRouter(router)} />
                </LocalizationProvider>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    duration={2000}
                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={apiLoading}
                    //onClick={() => setOpen(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </ThemeProvider>
        </LoadScript>
    );
  
}

export default App;
