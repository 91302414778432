import axios from "axios";
import moment from "moment";
import { PRICE_ESTIMATION_FAIL, PRICE_ESTIMATION_SUCCESS, PRODUCT_LIST_FAIL, PRODUCT_LIST_SUCCESS, TOKEN_FAIL, TOKEN_SUCCESS } from "../constants/gettConstants";
import { LOADING } from "../constants/userConstants";


const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
};

export const verifyUserToken = () => async(dispatch) => {

    
    let token = await localStorage.getItem("getttoken");

    if(token){
        const decodedJwt = parseJwt(token);
        if (decodedJwt.exp * 1000 > Date.now()) {
       
            dispatch(getProducts(token))
        }
        else{
            token = getToken()
            dispatch(getProducts(token))
        }
    }
    else {
        token = dispatch(getToken())
        dispatch(getProducts(token))

    }
}




//Get Token
export const getToken = () => async(dispatch) => {
    const formData = new FormData();
    formData.append("client_id", process.env.REACT_APP_GETIT_CLIENTID);
    formData.append("client_secret", process.env.REACT_APP_GETIT_SECRET);
    formData.append("grant_type", "client_credentials");
    formData.append("scope", "order");
    await axios.post("https://business-api.gett.com/oauth/token", formData,{
        headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async res => {
        await localStorage.setItem("getttoken", res.data?.access_token)
        return res.data?.access_token;
    })
    .catch(err => {
 
        dispatch({
            type: TOKEN_FAIL,
            payload: err?.message
        })
    })
}

export const getProducts = (token) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let bookingData = JSON.parse(await localStorage.getItem("booking")) 
    const data = {
        origin: {
            lat: bookingData?.location_from?.latitude,
            lng: bookingData?.location_from?.longitude
        },
        destination: {
            lat: bookingData?.location_to?.latitude,
            lng: bookingData?.location_to?.longitude
        },
        scheduled_at : moment(bookingData?.pickupdate).format("yyyy-MM-DDThh:mm:ssZ")
    }

    await axios.post(`https://business-api.gett.com/v1/products?businessId=${process.env.REACT_APP_GETT_BUSINESS}`, data,{
        headers: { 
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${token}`
        },
    })
    .then(res => {

        dispatch({
            type: LOADING,
            payload: true
        })

        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: res.data?.products?.transportation
        })
    })
    .catch(err => {
  
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: err?.message
        })
    })

}

//Get Price Estimation

export const getEstimatedPrice = (data, productId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let token = await localStorage.getItem("getttoken");
    await axios.post(`https://business-api.gett.com/v1/price-estimate?businessId=${process.env.REACT_APP_GETT_BUSINESS}`, data,{
        headers: { 
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${token}`
        },
    })
    .then(res => {

        dispatch({
            type: LOADING,
            payload: true
        })

        dispatch({
            type: PRICE_ESTIMATION_SUCCESS,
            payload: {
                productId: productId,
                estimation: res?.data
            }
        })
    })
    .catch(err => {
     
        dispatch({
            type: PRICE_ESTIMATION_FAIL,
            payload: err?.message
        })
    })
}