import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import AboutusContent from './AboutusContent';

const AboutUs = () => {

    return (
        <Layout head={"About Us"}>
            <AboutusContent/>   
        </Layout>
    )
}

export default AboutUs
