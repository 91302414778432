import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import service_img1 from '../../assets/images/service_img1.jpg'
import service_img2 from '../../assets/images/service_img2.jpg'
import service_img3 from '../../assets/images/service_img3.jpg'
import service_img4 from '../../assets/images/service_img4.jpg'
import safety_first from '../../assets/images/safety-first.png'
import luxurious_fleet from '../../assets/images/luxurious-fleet.png'
import professional_chauffeurs from '../../assets/images/professional-chauffeurs.png'
import customized_solutions from '../../assets/images/customized-solutions.png'
import hours_support from '../../assets/images/24-hours-support.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const ServicesContent = () => {

    const navigate = useNavigate()

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const Home = () => {
        setAnchorElNav(null);
        navigate('/')
    };


    return (

        <div>
            <div className="about_sec1">

                <Grid container spacing={2} className="container_topspace">

                    <Grid item md={12} xs={12}>
                        <div className="rightside_imgholder">

                            <img src={service_img4} />

                        </div>

                    </Grid>

                </Grid>
            </div>
            <div className="about_sec1">

                <Grid container spacing={2} className="container_topspace">
                    <Grid item md={12} xs={12}>

                        <div className='our_service'>
                            {/* <h1 className="section_heading">Our Services</h1> */}
                            <ul className="service_list">

                                <li>
                                    <h2 className="topic_heading">Corporate Transportation</h2>
                                    <ul className="topic_point_list">
                                        <li>Reliable and punctual chauffeur-driven cars for executives and corporate events.</li>
                                        <li>Customized transportation solutions to suit your business needs.</li>
                                        <li>Access to our fleet of luxury vehicles equipped with modern amenities.</li>
                                    </ul>

                                </li>
                                <li>

                                    <h2 className="topic_heading">Airport Transfers</h2>
                                    <ul className="topic_point_list">
                                        <li>Stress-free airport transfers to and from all major airports.</li>
                                        <li>Prompt pickups and drop-offs to ensure you never miss a flight.</li>
                                        <li>Meet and greet service available for VIP clients.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="topic_heading">Wedding Limousine Service</h2>

                                    <ul className="topic_point_list">
                                        <li>Make your special day even more memorable with our luxurious wedding limousine service.</li>
                                        <li>Elegant vehicles decorated to complement your wedding theme.</li>
                                        <li>Professional chauffeurs dedicated to making your day stress-free.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="topic_heading">Event Transportation</h2>

                                    <ul className="topic_point_list">
                                        <li>Seamless transportation solutions for events of all sizes.</li>
                                        <li>Fleet options ranging from sedans to spacious vans, accommodating your group size comfortably.</li>
                                        <li>On-time arrivals and departures to keep your event running smoothly.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="topic_heading">City Tours and Special Occasions</h2>

                                    <ul className="topic_point_list">
                                        <li>Explore the city in style with our personalized city tour packages.</li>
                                        <li>Celebrate birthdays, anniversaries, and other special occasions in luxury and comfort.</li>
                                        <li>Flexible scheduling to accommodate your itinerary and preferences.</li>
                                    </ul>
                                </li>
                                <li>

                                    <h2 className="topic_heading">Prom and Graduation Services</h2>

                                    <ul className="topic_point_list">
                                        <li>Arrive at your prom or graduation in style with our chauffeur-driven luxury vehicles.</li>
                                        <li>Safe and reliable transportation for students and their guests.</li>
                                        <li>Special packages available for group bookings.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="topic_heading">VIP Transportation</h2>

                                    <ul className="topic_point_list">
                                        <li>Exclusive transportation services for VIPs, celebrities, and dignitaries.</li>
                                        <li>High-end vehicles with privacy features for a discreet travel experience.</li>
                                        <li>Dedicated VIP concierge service to cater to your every need.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="topic_heading">Concierge Services</h2>

                                    <ul className="topic_point_list">
                                        <li>Beyond transportation, we offer personalized concierge services to enhance your travel experience.</li>
                                        <li>Restaurant reservations, event tickets, hotel bookings, and more arranged at your request.</li>
                                        <li>Let us take care of the details while you focus on enjoying your trip.</li>
                                    </ul>
                                </li>
                            </ul>




                        </div>

                    </Grid>

                </Grid>

            </div>




            <div className="about_sec4 ">
                <h3 className="section_heading">Why Choose A1Trips & Limo?</h3>

                <Grid container spacing={2} className="">



                    <Grid item md={3} xs={12}>

                        <div className="box_content">
                            <div className='icon_img'><img src={luxurious_fleet} /></div>

                            <h3 className="box_heading">Luxurious Fleet</h3>

                            <p>Our fleet comprises top-of-the-line vehicles meticulously maintained for your comfort and safety</p>

                        </div>

                    </Grid>
                    <Grid item md={3} xs={12}>

                        <div className="box_content">
                            <div className='icon_img'><img src={professional_chauffeurs} /></div>
                            <h3 className="box_heading">Professional Chauffeurs</h3>

                            <p>Experienced and courteous chauffeurs committed to providing you with a premium travel experience.</p>

                        </div>

                    </Grid>
                    <Grid item md={3} xs={12}>

                        <div className="box_content">
                            <div className='icon_img'><img src={customized_solutions} /></div>
                            <h3 className="box_heading">Customized Solutions</h3>

                            <p>Tailored transportation solutions to meet your specific requirements and preferences.</p>

                        </div>

                    </Grid>
                    <Grid item md={3} xs={12}>

                        <div className="box_content">
                            <div className='icon_img'><img src={hours_support} /></div>
                            <h3 className="box_heading">24/7 Customer Support</h3>

                            <p>Our dedicated customer support team is available round the clock to assist you with any queries or concerns.</p>

                        </div>

                    </Grid>


                </Grid>


            </div>



        </div>

    )
}

export default ServicesContent
