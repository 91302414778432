import React, { useState, useEffect } from 'react'
import { FormControl, FormGroup, FormLabel, Button, Divider, Box, Stack } from '@mui/material';
// import { Field, ErrorMessage } from 'formik';
import { TextField, Typography, InputAdornment } from '@mui/material'
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { useForm, Controller } from "react-hook-form";

const IconInput = ({  fieldLabel, error, fontSize, onChange, minValue, values }) => {

	const [counter, setCounter] = useState(values ? values : 0);
	const incrementCounter = () => {
		
		setCounter(counter + 1)
		
	};
	let decrementCounter = () => setCounter(counter - 1);

	useEffect(() => {
		onChange(counter)
		if (counter <= 0) {
			setCounter(minValue);
		}
	}, [counter])
	

	return(
		<Stack flexDirection={"row"}  marginTop={0} >
			<Typography 
				className='formText' 
				sx={{ m: 1, fontSize: fontSize ? { fontSize } : '12px' }}
				flex={0.7}
				
			>
				{fieldLabel}
			</Typography>
			<Remove 
				style={{ 
					backgroundColor: '#c67b15', 
					height: 20, width: 20, 
					color: 'white', 
					borderRadius: 0,
					cursor: 'pointer',
					marginTop:'8'
				}} 
				onClick={decrementCounter}
			/>
			
			<Typography 
				className='formText' 
				sx={{ m: 1, fontSize: fontSize ? { fontSize } : '20px' ,
				backgroundColor:'#EBEBEB',
				height: 20, 
				width: 15,
				borderRadius:0,
				paddingLeft:'6px',
				paddingTop:'2px'
		 	}}
			>
				{counter}
			</Typography>
			<Add 
				style={{ 
					backgroundColor: '#c67b15', 
					height: 20, width: 20, 
					color: 'white', 
					borderRadius: 0,
					marginTop:'8',
					cursor: 'pointer' 
				}} 
				onClick={incrementCounter}
			/>
		</Stack>
	)
}

export default IconInput
