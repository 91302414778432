import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@mui/material';
import { capitalize, isObject } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import Blue from '../assets/images/blue.png'
import Red from '../assets/images/red.png'
import distancePin from '../assets/images/distancePin.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import axios from 'axios';
import * as geolib from 'geolib';
import { toast } from 'react-toastify';
import customAxios from '../CustomAxios';
import { CameraFeed } from './CameraFeed';
import { Close, CloseOutlined, CloudDownload } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { IMAGE_URL, NO_SHOW_IMG } from '../config/Constants';
import Camera from './Camera';
import { Modal } from '@mui/material';



const DriverTrip = () => {

    const [open, setOpen] = useState(false)
    const [noShow, setNoShow] = useState(false)
    const [showImage, setShowImage] = useState(false)
    const [selectedImage, setSelectedImage] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [aditionalStops, setAditionalStops] = useState(null)
    const [booking_data, SetBookingData] = useState(null)
    const [files, setFiles] = useState([])

    const [searchParams, setSearchParams] = useSearchParams();


    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };



    const [trip, setTrip] = useState(null)
    const [loading, setLoading] = useState(false)


    const extraSeat = () => {
        let result = trip?.childrens + trip?.minors + trip?.baby_seats + trip?.booster_seats
        return result
    }



    useEffect(() => {
        getOtherDriverTrips()
    }, [])


    const getOtherDriverTrips = async () => {
        setLoading(true)
        await customAxios.get(`otherdriver/${searchParams.get("id")}`)
            .then(async response => {
                if (isObject(response?.data?.data)) {
                    setTrip(response?.data?.data)
                    setAditionalStops(JSON.parse(response?.data?.data?.stops))
                    SetBookingData(JSON.parse(response?.data?.data?.booking_data))
                }
                else {
                    setTrip(null)
                }
                setLoading(false)
            })
            .catch(async error => {
                setTrip(null)
                setLoading(false)
            })
    }


    const updateTripStatus = async (data) => {
        setLoading(true)
        await customAxios.get(`updatetripstatus`, {
            params: data
        })
            .then(async response => {
                setLoading(false)
                setTrip(response?.data?.data)
            })
            .catch(async error => {
                setLoading(false)
            });
    }

    const makeActiveTrip = () => {
        let data = {
            tripid: trip?.id,
            status: 2,
            time: moment().format("hh:mm A"),
            other_driver: searchParams.get("id")
        }
        setOpen(false)
        updateTripStatus(data)
    }

    const ChangeStatus = (status) => {
        let data = {
            tripid: trip?.id,
            status: status,
            time: moment().format("hh:mm A"),
            other_driver: searchParams.get("id")
        }
        updateTripStatus(data)
    }




    const getLocation = () => {
        setLoading(true)
        navigator.geolocation.getCurrentPosition((position) => {
            let currentLocation = {
                latitude: position?.coords?.latitude,
                longitude: position?.coords?.longitude
            }

            let tripLocation = {
                latitude: booking_data?.location_from?.latitude,
                longitude: booking_data?.location_from?.longitude
            }

            try {
                if (geolib?.isPointWithinRadius(currentLocation, tripLocation, 1000)) {
                    setLoading(false)
                    ChangeStatus(3);
                }
                else {
                    setLoading(false)
                    toast.warning(`Sorry. Try again when you are within 1km radius`)
                }
            } catch (error) {
                setLoading(false)
            }

        });
    }

    const pob = () => {
        setNoShow(false)
        ChangeStatus(4)
    }

    const dropoff = () => {
        ChangeStatus(5)
    }

    const completed = () => {
        ChangeStatus(6)
    }

    const enableNoShow = () => {
        setNoShow(true)
        setShowMore(false)
    }


    const renderTripButton = (status) => {
        switch (status) {
            case 1:
                return (
                    <Button fullWidth={true} style={{ backgroundColor: '#c67b15', color: '#fff' }} onClick={handleClickOpen}>
                        START TRIP
                    </Button>
                )

            case 2:
                return (
                    <Button fullWidth={true} style={{ backgroundColor: ' #c67b15', color: '#fff' }} onClick={getLocation}>
                        ON LOCATION
                    </Button>
                )
            case 3:
                return (
                    <Box display={"flex"} flexDirection="row" width={"100%"}>
                        <Button fullWidth={true} style={{ backgroundColor: ' #c67b15', color: '#fff', marginRight: 5, width: 150 }} onClick={pob} >
                            POB
                        </Button>
                        <Button fullWidth={true} style={{ backgroundColor: ' #c67b15', color: '#fff', marginLeft: 5, width: 150 }} onClick={enableNoShow}>
                            No Show
                        </Button>
                    </Box>
                )
            case 4:
                return (
                    <Button fullWidth={true} style={{ backgroundColor: ' #c67b15', color: '#fff' }} onClick={dropoff}>
                        DROP OFF
                    </Button>
                )
            case 5:
                return (
                    <Button fullWidth={true} style={{ backgroundColor: ' #c67b15', color: '#fff' }} onClick={completed}>
                        COMPLETED
                    </Button>
                )

            case 6:
                return (
                    <Button fullWidth={true} disabled style={{ backgroundColor: ' #c67b15', color: '#fff' }} onClick={completed}>
                        COMPLETED
                    </Button>
                )

            case 7:
                return (
                    <Button fullWidth={true} disabled style={{ backgroundColor: ' #c67b15', color: '#fff' }} onClick={completed}>
                        No Show
                    </Button>
                )

            case 8:
                return (

                    <Button fullWidth={true} style={{ backgroundColor: ' #c67b15', color: '#fff' }} onClick={dropoff}>
                        DROP OFF
                    </Button>
                )

            case 9:
                return (
                    <Button fullWidth={true} disabled style={{ backgroundColor: ' #c67b15', color: '#fff' }}>
                        Unconfirmed
                    </Button>
                )

            case 10:
                return (
                    <Button fullWidth={true} disabled style={{ backgroundColor: ' #c67b15', color: '#fff' }}>
                        Cancelled
                    </Button>
                )

            case 11:
                return (
                    <Button style={{ backgroundColor: '#c67b15', color: '#fff' }} onClick={handleClickOpen}>
                        START TRIP
                    </Button>
                )



            default:
                return (
                    <Button disabled fullWidth style={{ backgroundColor: ' #c67b15', color: '#fff' }}>
                        Other
                    </Button>
                )
        }
    }

    const showMoreDetails = () => {
        setShowMore(!showMore)
    }



    const UploadImages = (file) => {
        if (files.length > 4) {
            toast.warning("Maximum of 4 files are allowed")
        }
        else {
            // file.name = 'image.jpeg';
            // file.lastModified = new Date();
            setFiles((prev) => prev ? [...prev, file] : [file])
        }

    }

    const noShowSuccess = (data) => {
        setTrip(data)
        setNoShow(false)
        setLoading(false)
    }

    const closeNoShow = () => {
        setNoShow(false)
    }



    if (!trip) {
        return (
            <Typography>No Trips Found</Typography>
        )

    }

    const scheduledPickUpTime = () => {
        if (trip?.arrival === "Arrival") {
            if (trip?.buffer_time) {
                return moment(`${trip?.flightdate} ${trip?.flighttime}`, "DD-MM-YYYY hh:mm A").add(parseInt(trip?.buffer_time), 'minutes').format("hh:mm A")
            }
            else {
                return trip?.pickuptime
            }
        }
        else if (trip?.pickup_loc_type === "Cruise") {
            if (trip?.buffer_time && trip?.disembarkment) {
                return moment(`${trip?.pickupdate} ${trip?.disembarkment}`, "YYYY-MM-DD hh:mm A").add(parseInt(trip?.buffer_time), 'minutes').format("hh:mm A")
            }
        }
        else {
            return trip?.pickuptime
        }
    }


    const airportDetails = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', alignItems: 'center', paddingTop: 2 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '97%', justifyContent: 'center' }}>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={14} fontWeight={600} textAlign="center">Flight No</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={14} fontWeight={600} textAlign="center">Flight Date</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={14} fontWeight={600} textAlign="center">Flight Time</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={14} fontWeight={600} textAlign="center">Buffer Time</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '97%', justifyContent: 'center', border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', borderRadius: 5, height: 60, padding: 3 }}>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={14} textAlign="center" fontWeight={"bold"}>{trip?.flightno}</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={14} textAlign="center" fontWeight={"bold"}>{trip?.flightdate}</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={14} textAlign="center" fontWeight={"bold"}>{trip?.flighttime}</Typography>
                    <Typography flex={0.25} fontFamily="Nunito" color={'#0A2638'} fontSize={14} textAlign="center" fontWeight={"bold"}>{trip?.buffer_time}</Typography>
                </div>
            </div>
        )
    }

    const departureDetails = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', alignItems: 'center', paddingTop: 2 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '97%', justifyContent: 'center' }}>
                    <Typography flex={0.33} fontFamily="Nunito" color={'#0A2638'} fontSize={14} fontWeight={600} textAlign="center">Flight No</Typography>
                    <Typography flex={0.33} fontFamily="Nunito" color={'#0A2638'} fontSize={14} fontWeight={600} textAlign="center">Flight Date</Typography>
                    <Typography flex={0.33} fontFamily="Nunito" color={'#0A2638'} fontSize={14} fontWeight={600} textAlign="center">Flight Time</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '97%', justifyContent: 'center', border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', borderRadius: 5, height: 60, padding: 3 }}>
                    <Typography flex={0.33} fontFamily="Nunito" color={'#0A2638'} fontSize={14} textAlign="center" fontWeight={"bold"}>{trip?.departure_flight_no}</Typography>
                    <Typography flex={0.33} fontFamily="Nunito" color={'#0A2638'} fontSize={14} textAlign="center" fontWeight={"bold"}>{moment(trip?.departure_flight_date).format("DD-MM-YYYY")}</Typography>
                    <Typography flex={0.33} fontFamily="Nunito" color={'#0A2638'} fontSize={14} textAlign="center" fontWeight={"bold"}>{moment(trip?.departure_flight_date).format("hh:mm A")}</Typography>
                </div>
            </div>
        )
    }


    const handleImageClose = () => {
        setShowImage(false)
    }


    return (
        <div style={{ display: 'flex', flex: 1, width: '98%', padding: 10 }}>


            <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', flexDirection: 'column', border: '1px solid #EEEEEE', flex: 1 }}>

                <div style={{ backgroundColor: '#333333', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', borderTopLeftRadius: 20, borderTopRightRadius: 10, height: 30, position: 'relative' }}>
                    {extraSeat() > 0 && <div
                        style={{
                            position: 'absolute',
                            bottom: '-300%',
                            right: '5%',
                            width: 30,
                            height: 30,
                            background: 'rgb(48 130 255)',
                            display: 'flex',
                            color:'white',
                            justifyContent: 'center',
                            alignItems:'center',
                            borderRadius:15
                        }}>E</div>}
                    <label style={{ color: '#fff', fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 18 }} >{capitalize(trip?.arrival)}</label>
                    {!showMore && <KeyboardArrowRight style={{ color: '#fff', position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} onClick={showMoreDetails} />}
                    {showMore && <KeyboardArrowDownIcon style={{ color: '#fff', position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} onClick={showMoreDetails} />}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', border: '1px solid #EEEEEE' }}>
                    <div style={{ padding: 5 }}>
                        <Typography color={'#0759B7'} fontWeight={600} fontFamily="Nunito" fontSize={14}  >{trip?.vehicletype?.vehicle_type}</Typography>
                    </div>
                    <div style={{ padding: 5 }}>
                        <Typography color={'#0759B7'} fontWeight={600} fontFamily="Nunito" fontSize={14}  >{moment(trip?.pickupdate).format("DD-MM-YYYY")}</Typography>
                    </div>
                    <div style={{ padding: 5 }}>
                        <Typography color={'#0759B7'} fontWeight={600} fontFamily="Nunito" fontSize={14}  >{trip?.pickuptime}</Typography>
                    </div>
                </div>
                {showMore && <div style={{ width: '100%', border: '1px solid #EEEEEE', display: 'flex', justifyContent: 'center', backgroundColor: '#00000029', paddingTop: 3, paddingBottom: 3 }}>
                    <Typography color={'#0A2638'} fontWeight={600} fontFamily="Nunito" fontSize={14} >Trip ID: #{trip?.id}</Typography>
                </div>}

                {(showMore && trip?.arrival === "Arrival") && airportDetails()}
                {(showMore && trip?.arrival === "Departure") && departureDetails()}
                {(showMore && trip?.pickup_loc_type === "Cruise") && <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '98%', justifyContent: 'center' }}>
                    <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">Cruise Name</Typography>
                    <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">Time of Disembarkment</Typography>
                </div>}
                {(showMore && trip?.pickup_loc_type === "Cruise") && <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '98%', justifyContent: 'center', border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF' }}>
                    <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.cruise_no}</Typography>
                    <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={12} textAlign="center">{trip?.disembarkment}</Typography>
                </div>}
                {showMore && <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '98%', justifyContent: 'flex-start', paddingTop: 3 }}>
                    <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontWeight={600} fontSize={14} textAlign="left" paddingLeft={1}>{"Scheduled Pickup Time"}</Typography>
                    <div style={{ backgroundColor: '#EFEFEF', height: 25, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5, borderRadius: 5, padding: 2 }}>
                        <Typography color={'#0A2638'} flex={0.5} fontFamily="Nunito" fontSize={14} fontWeight={"bold"} paddingLeft={1}>{scheduledPickUpTime()}</Typography>
                    </div>
                </div>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '98%' }}>
                    <img src={Blue} width={30} height={50} />
                    <Typography color={'#0A2638'} fontWeight={700} fontFamily="body" numberOfLines={2} flexWrap="wrap" fontSize={14} ml={2}>{trip?.location_from}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '98%', borderLeftWidth: 1, borderColor: 'red', border: '1px solid red', borderWidth: '0px 0px 0px 2px', position: 'relative', minHeight: 10, marginLeft: 27 }}>
                    {aditionalStops?.map(stop => (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '90%', marginLeft: 25 }}>
                            <img src={Blue} width={20} height={30} />
                            <Typography color={'#0A2638'} fontWeight={700} fontFamily="body" numberOfLines={2} fontSize={12} ml={1}>{stop?.cityname}</Typography>
                        </div>))}
                    <KeyboardArrowDownIcon style={{ color: 'red', position: 'absolute', left: -12.5, bottom: -10 }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '98%' }}>
                    <img src={Red} width={30} height={50} />
                    <Typography color={'#0A2638'} fontWeight={700} fontFamily="body" numberOfLines={2} fontSize={14} ml={2} flexWrap="wrap">{trip?.location_to}</Typography>
                </div>
                {showMore &&
                    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '98%' }}>
                        <div style={{ width: '98%', border: '1px solid #EEEEEE' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', justifyContent: 'flex-start', paddingTop: 4 }}>
                            <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={14} textAlign="left" paddingLeft={1} fontWeight={"600"}>{"Passenger Name"}</Typography>
                            <div style={{ backgroundColor: '#EFEFEF', height: 25, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5, borderRadius: 5, padding: 2 }}>
                                <Typography color={'#0A2638'} flex={1} fontFamily="Nunito" fontSize={14} paddingLeft={1} fontWeight={"bold"}>{trip?.name}</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', justifyContent: 'flex-start', paddingTop: 2, marginBottom: 2 }}>
                            <Typography color={'#0A2638'} flex={0.5} fontFamily="Nunito" fontSize={14} textAlign="left" fontWeight={600} paddingLeft={1}>{"Contact No"}</Typography>
                            <div style={{ backgroundColor: '#EFEFEF', height: 25, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5, borderRadius: 5, padding: 2 }}>
                                <Typography color={'#0A2638'} flex={0.5} fontFamily="Nunito" fontWeight={"bold"} fontSize={12} paddingLeft={1}>{trip?.phone}</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'space-between', paddingTop: 4 }}>
                            <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={14} textAlign="center" fontWeight={600}>Adults</Typography>
                            {/* <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={14} textAlign="center" fontWeight={600}>Childs</Typography> */}
                            <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={14} textAlign="center" fontWeight={600}>Luggage</Typography>
                            <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={14} textAlign="center" fontWeight={600}>Bags</Typography>
                            {trip?.itenary && <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={14} textAlign="center">{trip?.itenary ? 'Itinerary' : ''}</Typography>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'space-between', paddingBottom: 5 }}>
                            <div style={{ display: 'flex', flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={14} fontWeight={"bold"} textAlign="center">{trip?.adults}</Typography>
                                </div>

                            </div>

                            <div style={{ display: 'flex', flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={14} fontWeight={"bold"} textAlign="center">{trip?.suitcase ? trip?.suitcase : 0}</Typography>
                                </div>

                            </div>
                            <div style={{ display: 'flex', flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={14} fontWeight={"bold"} textAlign="center">{trip?.carryon ? trip?.carryon : 0}</Typography>
                                </div>

                            </div>
                            {trip?.itenary && <div style={{ display: 'flex', flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                                {trip?.itenary && <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 9, paddingRight: 9 }}>
                                    <a href={trip?.itenary}><CloudDownload style={{ fontSize: 14, cursor: 'pointer' }} /></a>
                                </div>}

                            </div>}
                        </div>
                        <Typography fontFamily="Nunito" fontSize={12} marginLeft={4} color={"red"} textAlign="flex-start">{"Extra"}</Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'space-between' }}>
                            <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={14} textAlign="center" fontWeight={600}>Childs Seats</Typography>
                            <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={14} textAlign="center" fontWeight={600}>Baby Seats</Typography>
                            <Typography color={'#0A2638'} flex={0.25} fontFamily="Nunito" fontSize={14} textAlign="center" fontWeight={600}>Booster Seats</Typography>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'space-between', paddingBottom: 5 }}>
                            <div style={{ display: 'flex', flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={14} fontWeight={"bold"} textAlign="center">{trip?.childrens ? trip?.childrens : 0}</Typography>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={14} fontWeight={"bold"} textAlign="center">{trip?.baby_seats}</Typography>
                                </div>

                            </div>

                            <div style={{ display: 'flex', flex: 0.25, justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid #EEEEEE', backgroundColor: '#EFEFEF', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Typography color={'#0A2638'} fontFamily="Nunito" fontSize={14} fontWeight={"bold"} textAlign="center">{trip?.booster_seats}</Typography>
                                </div>

                            </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', justifyContent: 'flex-start', paddingBottom: 20 }}>
                            <Typography flex={0.5} color={'#0A2638'} fontFamily="Nunito" fontSize={14} fontWeight={600} textAlign="left" paddingLeft={1}>{"Special Instructions"}</Typography>
                            <div style={{ backgroundColor: '#EFEFEF', height: 25, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5, borderRadius: 5, padding: 2 }}>
                                <Typography color={'#0A2638'} flex={0.5} fontFamily="Nunito" fontSize={14} fontWeight={"bold"} paddingLeft={1}>{trip?.specialinstructions}</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: 350, overflowX: 'scroll', justifyContent: 'center', gap: 2 }}>
                            {trip?.noshowimages && trip?.noshowimages?.map((img, i) => (
                                <img src={`${NO_SHOW_IMG}${img.image}`} width={50} height={50} onClick={() => {
                                    setSelectedImage(`${NO_SHOW_IMG}${img.image}`);
                                    setShowImage(true)
                                }} />
                            ))}
                        </div>
                    </div>}
                {!showMore && <div style={{ width: '100%', border: '1px solid #EEEEEE', display: 'flex', justifyContent: 'center', backgroundColor: '#00000029', paddingTop: 3, paddingBottom: 3 }}>
                    <Typography color={'#0A2638'} fontWeight={600} fontFamily="Nunito" fontSize={14} >Trip ID: #{trip?.id}</Typography>
                </div>}
                <div style={{ padding: 5, paddingBottom: 20 }}>
                    {renderTripButton(parseInt(trip?.status))}
                </div>
                <Modal
                    open={noShow}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box display={"flex"} alignItems={"center"} flexDirection={"column"} gap={5} justifyContent={"center"}>
                        <Camera onCapture={UploadImages} noShowSuccess={noShowSuccess} tripId={trip?.id} id={searchParams.get("id")} closeNoShow={closeNoShow} />


                    </Box>
                </Modal>
                <Modal
                    open={showImage}
                    onClose={handleImageClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box display={"flex"} alignItems={"center"} flexDirection={"column"} gap={5} justifyContent={"center"}>
                        <img src={selectedImage} width={"100%"} />
                    </Box>
                </Modal>

            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to start Trip?.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={makeActiveTrip} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default DriverTrip