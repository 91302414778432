import {
    NEW_VIP_BOOKING_SUCCESS,
    NEW_VIP_BOOKING_FAIL,
    NEW_POINT_BOOKING_SUCCESS,
    NEW_POINT_BOOKING_FAIL,
    RESET_ERROR,
    VEHICLE_AVAILABILITY_SUCCESS,
    VEHICLE_AVAILABILITY_FAIL,
    BOOKING_DATA,
    SELECTED_VEHICLE,
    ORDER_DATA,
    NEW_BOOKING_SUCCESS,
    RESET_BOOKING,
    COUPON_VERIFY_SUCCESS,
    COUPON_VERIFY_FAIL,
    CREDIT_TERMS_SUCCESS,
    CREDIT_TERMS_FAIL,
    ADD_BOOKING,
    REMOVE_ORDER_ITEM,
    PASSENGERS_DETAILS,
    RESET_ORDER_DATA,
    SET_SELECTED_BOOKING,
    NEW_BOOKING_FAIL,
    BOOKING_TYPE,
    RESET_BOOKING_DATA,
    BOOKING_INPUT
} from "../constants/bookingConstants";

export const bookingReducer = (state = {}, action) => {
    switch (action.type) {

        case NEW_VIP_BOOKING_SUCCESS:
            return {
                ...state,
                newVipBooking: true
            }

        case NEW_VIP_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case NEW_POINT_BOOKING_SUCCESS:
            return {
                ...state,
                newBooking: true
            }

        case NEW_POINT_BOOKING_FAIL:
        case CREDIT_TERMS_FAIL:
        case NEW_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case VEHICLE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availableVehicles: action.payload,
                enablegett: false
            }

        case VEHICLE_AVAILABILITY_FAIL:
            return {
                ...state,
                error: action.payload,
                availableVehicles: null,
                enablegett: true
            }

        case BOOKING_DATA:
            return {
                ...state,
                bookingData: action.payload,
                bookingChanged: true
            }

        case ORDER_DATA:
            return {
                ...state,
                orderData: state?.orderData ? [...state.orderData, action.payload] : [action.payload]
            }

        case SELECTED_VEHICLE:
            return {
                ...state,
                selectedVehicle: action.payload
            }

        case RESET_ERROR:
            return {
                ...state,
                error: null,
                newBooking: null,
                bookingChanged: null,
                newVipBooking: null,
                bookingSuccess: null,
                couponError: null
            }

        case NEW_BOOKING_SUCCESS:
            return {
                ...state,
                bookingSuccess: true,
                bookingResponse: action?.payload
            }

        case PASSENGERS_DETAILS:
            return {
                ...state,
                passengers: action.payload
            }

        case RESET_BOOKING:
            return {
                ...state,
                bookingData: null,
                orderData: null,
                selectedVehicle: null,
                availableVehicles: null,
                couponApplied: null,
                couponDetails: null
            }

        case ADD_BOOKING:
            return {
                ...state,
                bookingData: null,
                selectedVehicle: null,
                availableVehicles: null,
                passengers: null,
                selectedBooking: null
            }

        case REMOVE_ORDER_ITEM:
            return {
                ...state,
                orderData: state.orderData.filter((order, index) => index !== action.payload)
            }

        case COUPON_VERIFY_SUCCESS:
            return {
                ...state,
                couponDetails: action.payload,
                couponApplied: true
            }

        case COUPON_VERIFY_FAIL:
            return {
                ...state,
                couponError: action.payload,
                couponDetails: null,
                couponApplied: false
            }

        case CREDIT_TERMS_SUCCESS:
            return {
                ...state,
                creditTerms: action.payload
            }

        case RESET_ORDER_DATA:
            return {
                ...state,
                orderData: action.payload
            }

        case SET_SELECTED_BOOKING:
            return {
                ...state,
                selectedBooking: action.payload.booking,
                currentVehicle: action.payload.selectedVehicle,
                selectedId: action.payload.id
            }

        case BOOKING_TYPE:
            return {
                ...state,
                bookingType: action.payload,
                enablegett: action.payload === "PAL" ? false : true
            }

        case RESET_BOOKING_DATA:
            return {
                ...state,
                bookingData: null,
                selectedBooking: null
            }

        case BOOKING_INPUT:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }


        default:
            return state;
    }
}