import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout'
import './style.scss'
import { Grid ,Snackbar,Alert, Box} from '@mui/material'
import TopUpForm from './TopUpForm'
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
const TopUp = ({head}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };
    const { newTopup ,error} = useSelector(state => state.topup)
    useEffect(() => {
        if(error){
            
            dispatch({
                type: RESET_ERROR
            })
        }
        
        if(newTopup){
            setOpen(true)
                dispatch({
                    type: RESET_ERROR
                })
                
        }
       
    }, [error,newTopup])
    
 
    return (
        <Layout head={head}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Topup added Successfully
                </Alert>
            </Snackbar>
            
            <Grid style={{ display: 'flex',flexDirection:'row' , justifyContent:'flex-start'}}>
                <h1 class='underline'><span style={{ textDecorationLine:'underline', textDecorationColor:'#c67b15' }}>Top Up</span> Details</h1>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box width={{ sx: '98%', sm: '60%', md: '90%', lg: '60%', xl: '50%' }} alignSelf="center">
                    <TopUpForm heading={false} />
                </Box>
            </div>
        </Layout>
    )
}

export default TopUp
