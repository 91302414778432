import { 
    LOADING, 
    NEW_VIP_BOOKING_SUCCESS, 
    NEW_VIP_BOOKING_FAIL,
    NEW_POINT_BOOKING_FAIL,
    NEW_POINT_BOOKING_SUCCESS,
    VEHICLE_AVAILABILITY_SUCCESS,
    VEHICLE_AVAILABILITY_FAIL,
    NEW_BOOKING_SUCCESS,
    NEW_BOOKING_FAIL,
    COUPON_VERIFY_SUCCESS,
    COUPON_VERIFY_FAIL,
    CREDIT_TERMS_SUCCESS,
    CREDIT_TERMS_FAIL,
    BOOKING_TYPE,
    BOOKING_INPUT

} from "../constants/bookingConstants";
import axios from '../../CustomAxios'
import { MY_TRANSFER_AVAILABILITY_SUCCESS } from "../constants/myTransferConstants";
import { vehicleRates } from "../../helper/vehicleRates";
import { getMarkup } from "./markupActions";

//Get Trip Availability 
export const getTripAvailability = (data) => async(dispatch, getState) => {

    const { user, additionalStops } = getState().auth

    if(user?.role === "agent"){
        await dispatch(getMarkup(user?.id))
    }
    

    const { markupLists } = getState().markup

    const bookinData = await JSON.parse(localStorage.getItem("booking"));


    dispatch({
        type: LOADING,
        payload: true
    })


    
    await axios.post(`verifycordinates`, data)
    .then(async response => {
     
        dispatch({
            type: LOADING,
            payload: false
        })
        const { api_type, data, getMarkups, userMarkup } = response?.data
        if(api_type === "PAL"){
            dispatch({
                type: BOOKING_TYPE,
                payload: "PAL"
            })

            if(data?.length === 0){
                dispatch({
                    type: VEHICLE_AVAILABILITY_SUCCESS,
                    payload: []
                })
            }
            else{
                let datas = await vehicleRates(response?.data?.data, bookinData, getMarkups?.additionalStop, user, userMarkup?.[0])

                dispatch({
                    type: VEHICLE_AVAILABILITY_SUCCESS,
                    payload: datas
                })
            }

            
            
        }
        else if(api_type === "mytransfers"){
            dispatch({
                type: BOOKING_TYPE,
                payload: "MYTRANSFER"
            })
            dispatch({
                type: MY_TRANSFER_AVAILABILITY_SUCCESS,
                payload: response?.data?.data
            })
        }
        else{
            dispatch({
                type: VEHICLE_AVAILABILITY_SUCCESS,
                payload: []
            })
        }
       
    })
    .catch(async error => {

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: VEHICLE_AVAILABILITY_FAIL,
            payload: error
        })

    });
}

//Add VIP BOOKING
export const addVipBooking = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`vipbooking`, data)
    .then(async response => {

        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_VIP_BOOKING_SUCCESS,
            payload: response.data
        })
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_VIP_BOOKING_FAIL,
            payload: error
        })

    });
}


//Add POINT TO POINT BOOKING
export const addPointBooking = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`createbooking`, data)
    .then(async response => {
    
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_POINT_BOOKING_SUCCESS,
            payload: response.data
        })
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_POINT_BOOKING_FAIL,
            payload: error
        })

    });
}

//New Booking
export const newBooking = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`createbooking`, data)
    .then(async response => {
    
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_BOOKING_SUCCESS,
            payload: response.data.data
        })
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_BOOKING_FAIL,
            payload: error
        })

    });
}

//Get Hourly Rate
export const getHourlyRate = (data) => async(dispatch, getState) => {

    const { user, additionalStops } = getState().auth

    const { markupLists } = getState().markup

    const bookinData = await JSON.parse(localStorage.getItem("booking"));

    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`gethourlyrate`, data)
    .then(async response => {
    
        dispatch({
            type: LOADING,
            payload: false
        })
        
        if(response?.data?.data?.length === 0){
                dispatch({
                    type: VEHICLE_AVAILABILITY_SUCCESS,
                    payload: []
                })
        }
        else{
            const { data, getMarkups, userMarkup } = response?.data
            let datas = await vehicleRates(data, bookinData, getMarkups?.additionalStop, user, userMarkup?.[0])

            dispatch({
                type: VEHICLE_AVAILABILITY_SUCCESS,
                payload: datas
            })
        }
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: VEHICLE_AVAILABILITY_FAIL,
            payload: error
        })

    });
}

export const verifyCoupon = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`coupon-verify`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: COUPON_VERIFY_SUCCESS,
            payload: response.data.data
        })
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: COUPON_VERIFY_FAIL,
            payload: error?.message
        })

    });
}


//Get User Credit Terms
export const getUserCreditTerms = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`creditterms`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        if(response.data.data?.length > 0){
            dispatch({
                type: CREDIT_TERMS_SUCCESS,
                payload: response.data.data[0]
            })
        }
        
       
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: CREDIT_TERMS_FAIL,
            payload: error?.message
        })

    });
}

//check airport
export const checkAirportType = (data) => async(dispatch) => {
    
    await axios.post(`airport/check`, data)
    .then(async response => {
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'airportType',
                value: response.data.message
            }
        })
       
    })
    .catch(async error => {

    });
}