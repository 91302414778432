import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Divider, Typography } from '@mui/material'
import './style.scss'


const MainStop = ({ address, count, color }) => {
  return (
    <div className='location_innerbox'>
      <span className='img_icon' style={{ position: 'relative' }}>
        <LocationOnIcon style={{ width: 20, height: 20, color: 'rgb(237 186 55)' }} />
        {/* <div style={{ fontSize: 8, width: 10, height: 10, borderRadius: 5, backgroundColor: color, color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, right: -3, position: 'absolute' }}>{count}</div> */}
      </span>
      <span className='loctext' >{address}</span>
    </div>
  )
}

export default MainStop