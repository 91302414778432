import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import PrivacyPolicyContent from './PrivacyPolicyContent';

const PrivacyPolicy = () => {

    return (
        <Layout head={"Privacy Policy"}>
            <PrivacyPolicyContent/>   
        </Layout>
    )
}

export default PrivacyPolicy
