import { 
    TRIP_LIST_FAIL,
    TRIP_LIST_SUCCESS,
    NEW_TRIP_FAIL, 
    NEW_TRIP_SUCCESS, 
    RESET_ERROR, 
    UPDATE_TRIP_SUCCESS,
    UPDATE_TRIP_FAIL,
    DELETE_TRIP_SUCCESS,
    DELETE_TRIP_FAIL,
    TRIP_LIST_BYDATE_SUCCESS,
    TRIP_LIST_BYDATE_FAIL
} from "../constants/tripConstants";

export  const tripReducer = (state = { }, action) => {
    switch(action.type){

        case NEW_TRIP_SUCCESS:
            return {
                ...state,
                tripLists : state.tripLists ? [...state.tripLists, action.payload.trip] : [ action.payload.trip ],
                newTrip: true
            }

        case NEW_TRIP_FAIL:
        case UPDATE_TRIP_FAIL:
        case TRIP_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case TRIP_LIST_BYDATE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_TRIP_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_TRIP_SUCCESS:
            return {
                ...state,
                tripLists: state.tripLists.filter(cou => cou._id !== action.payload ),
                deleteSuccess: true
            }
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                newTrip: null,
                deleteSuccess:null,
                updateSuccess:null
            }

        case TRIP_LIST_SUCCESS:
            return {
                ...state,
                tripLists: action.payload
            }
        case TRIP_LIST_BYDATE_SUCCESS:
            return {
                ...state,
                tripLists: action.payload
            }
        case UPDATE_TRIP_SUCCESS:
            
            return{
                ...state,
                tripLists: state.tripLists.map(cou => cou._id === action.payload._id ? action.payload : cou),
                updateSuccess: true
            }

        default:
            return state;
    }
}