import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgegroupImg from '../../../assets/images/IconsNew/age-group.png'
import BagImg from '../../../assets/images/IconsNew/luggage.png'
import Vehicle1Img from '../../../assets/images/Vehi1.jpg'
import '../../Summary/style.scss'
import { capitalize, isArray } from 'lodash'
import { BOOKING_TYPE, SELECTED_VEHICLE, SET_SELECTED_BOOKING } from '../../../Redux/constants/bookingConstants'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'




const Vehicle = memo(({ item }) => {

    const { bookingData } = useSelector(state => state.booking)
    const { user, additionalStops, myTransfer } = useSelector(state => state.auth)
    const { markupLists } = useSelector(state => state.markup)


    const navigate = useNavigate()

    const dispatch = useDispatch()



    let extraRate = 0;
    let rate = 0;
    let adminMarkup = 0;
    let agentmarkup = 0;
    let total = 0;
    let tax = 0;
    item?.extras.map(ext => {
        if (ext?.extraName === "Child seat" && bookingData?.childrens && bookingData?.childrens > 0) {
            extraRate += Math.ceil(ext?.price) * bookingData?.childrens;
        }
        else if (ext?.extraName === "Booster seat" && bookingData?.minors && bookingData?.minors > 0) {
            extraRate += Math.ceil(ext?.price) * bookingData?.minors;
        }
    })

    rate = parseFloat(item?.price);

    if (myTransfer && myTransfer?.value) {
        if (myTransfer?.value_type === "Percentage") {
            adminMarkup = Math.ceil((rate / 100) * parseFloat(myTransfer?.value))
        }
        else {
            adminMarkup = Math.ceil(myTransfer?.value)
        }
    }

    if (user?.role === "agent" && markupLists?.type === "fixed") {

        if (markupLists?.status === 1) {
            agentmarkup = Math.ceil(markupLists?.value);
        }
        if (myTransfer?.tax) {
            tax = Math.ceil(((rate + adminMarkup + extraRate + agentmarkup) / 100) * parseFloat(myTransfer?.tax))
        }


        total = Math.ceil(rate + adminMarkup + extraRate + agentmarkup + tax);
    }
    else {
        if (user?.role === "agent" && markupLists?.status === 1) {
            agentmarkup = Math.ceil((rate / 100) * parseFloat(markupLists?.value))
        }

        if (myTransfer?.tax) {
            tax = Math.ceil(((rate + adminMarkup + extraRate + agentmarkup) / 100) * parseFloat(myTransfer?.tax))
        }


        total = Math.ceil(rate + adminMarkup + extraRate + agentmarkup + tax);
    }

    const selectCurrentVehicle = (data) => {

        dispatch({
            type: BOOKING_TYPE,
            payload: 'MYTRANSFER'
        })

        let extraRate = 0;
        let rate = 0;
        let adminMarkup = 0;
        let agentmarkup = 0;
        let total = 0;
        let tax = 0;

        let extras = [];

        rate = Math.ceil(data?.price);
        data?.extras.map(ext => {
            if (ext?.extraName === "Child seat" && bookingData?.childrens > 0) {
                extraRate += Math.ceil(ext?.price) * bookingData?.childrens;
                extras.push({ extraId: ext?.extraId, amount: bookingData?.childrens })
            }
            else if (ext?.extraName === "Booster seat" && bookingData?.minors > 0) {
                extraRate += Math.ceil(ext?.price) * bookingData?.minors;
                extras.push({ extraId: ext?.extraId, amount: bookingData?.minors })
            }

        })
        if (myTransfer && myTransfer?.value > 0) {
            if (myTransfer?.value_type === "Percentage") {
                adminMarkup = Math.ceil((rate / 100) * parseFloat(myTransfer?.value))
            }
            else {
                adminMarkup = Math.ceil(myTransfer?.value)
            }
        }


        if (user?.role === "agent" && markupLists?.type === "fixed") {

            if (markupLists?.status === 1) {
                agentmarkup = Math.ceil(markupLists?.value);
            }

            if (myTransfer?.tax) {
                tax = Math.ceil(((rate + adminMarkup + extraRate + agentmarkup) / 100) * parseFloat(myTransfer?.tax))
            }



            total = Math.ceil(rate + adminMarkup + extraRate + agentmarkup + tax);
        }
        else {
            if (user?.role === "agent" && markupLists?.status === 1) {
                agentmarkup = Math.ceil((rate / 100) * parseFloat(markupLists?.value))
            }

            if (myTransfer?.tax) {
                tax = Math.ceil(((rate + adminMarkup + extraRate + agentmarkup) / 100) * parseFloat(myTransfer?.tax))
            }

            total = Math.ceil(rate + adminMarkup + extraRate + agentmarkup + tax);
        }




        let datas = {
            ...data,
            price: rate,
            adminMarkup: adminMarkup,
            agentmarkup: agentmarkup,
            total: total,
            taxValue: tax,
            extraRate: extraRate,
            extras
        }
        dispatch({
            type: SELECTED_VEHICLE,
            payload: datas
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedVehicle: datas,
                id: 'current'
            }
        })

        navigate("/passengerdetails")

    }



    return (
        <div className="vehicleBox">

            <div className="vehicleBox_img">
                {item?.imageURL ? <img src={`${item?.imageURL}`} style={{}} resizeMode={'contain'} /> : <img src={Vehicle1Img} style={{}} resizeMode={'contain'} />}
            </div>
            <div className="vehicleBox_details">
                <Typography className='nameText'>{item?.transportName}</Typography>
                <Box className="description_holder">
                    <Box className="descriptionList">
                        <div className="descriptionItem" >
                            <img className="descriptionItemImg" src={AgegroupImg} />
                            <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max. {item?.maxPassengers}</Typography>
                        </div>
                        <div className="descriptionItem" >
                            <img className="descriptionItemImg" src={BagImg} />
                            <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max. {item?.suitcases}</Typography>
                        </div>
                    </Box>
                    <Box className="vehicle_price_holder">
                        <Typography className='pricetext' flex={0.8}>S${total}</Typography>
                        <div className="btn-holder" >
                            <Button

                                variant="contained"
                                style={{
                                    backgroundColor: '#c67b15',
                                    height: 30,
                                }}
                                onClick={() => selectCurrentVehicle(item)}
                                className='buttonText'
                            >
                                Select
                            </Button>
                        </div>

                    </Box>

                </Box>


            </div>

        </div>
    )
})

export default Vehicle