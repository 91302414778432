import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import aboutus_img1 from '../../assets/images/aboutus_img1.jpg'
import aboutus_img2 from '../../assets/images/aboutus_img2.jpg'
import aboutus_img3 from '../../assets/images/aboutus_img3.jpg'
import safety_first from '../../assets/images/safety-first.png'
import reasonable_rates from '../../assets/images/resonable-rate.png'
import largest_fleet from '../../assets/images/largest-fleet.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const AboutUsContent = () => {


    return (

        <div>
            <div className="about_sec1">

                <Grid container spacing={2} className="container_topspace">
                    <Grid item md={6} xs={12}>

                        <div className="leftside_textholder">

                            <h3 className="section_heading">Experience the Difference</h3>

                            <p>When booking a limousine with us, you can expect a luxurious and comfortable ride with a professional chauffeur. We offer a variety of packages to suit your needs and preferences.</p>

                        </div>

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className="rightside_imgholder">

                            <img src={aboutus_img1} />

                        </div>

                    </Grid>

                </Grid>

            </div>

            <div className="about_sec2">

                <Grid container spacing={2} className="container_topspace">

                    <Grid item md={6} xs={12}>
                        <div className="leftside_imgholder">

                            <img src={aboutus_img2} />

                        </div>

                    </Grid>

                    <Grid item md={6} xs={12}>

                        <div className="rightside_textholder">

                            <h3 className="section_heading">Arrive in Style</h3>

                            <p>Our premium limousine service offers a comfortable and stylish way to travel for any occasion. From airport transfers to weddings, our professional chauffeurs will ensure a memorable experience.</p>
                            <p>At our core, we are dedicated to providing exceptional service that exceeds our clients' expectations.</p>
                           
                        </div>

                    </Grid>

                </Grid>

            </div>
         

        </div>

    )
}

export default AboutUsContent
