import { Box, Button, ButtonGroup, Divider, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { verifyUserToken } from '../../../Redux/actions/gettActions'
import { RESET_GETT, GETT_SELECTED_VEHICLE } from '../../../Redux/constants/gettConstants'
import AgegroupImg from '../../../assets/images/IconsNew/age-group.png'
import BagImg from '../../../assets/images/IconsNew/luggage.png'
import moment from 'moment'

const GetId = () => {


    const { products, error } = useSelector(state => state.gett)
    const { bookingData } = useSelector(state => state.booking)

    
    const dispatch = useDispatch()

    useEffect(() => {
        if(error){
            toast.error(error)
            dispatch({
                type: RESET_GETT
            })
        }
    }, [error])
    


    useEffect(() => {
        dispatch(verifyUserToken())
    }, [])


    const getEstimatedPrice = (vehicle) => {
        const stops = bookingData?.stops?.length;
        let userStop = [];
        if(stops > 0){
            userStop = bookingData?.stops?.map(stop => {
                return{
                    lat: stop?.stop_name?.latitude,
                    lng: stop?.stop_name?.longitude
                }
            })
        }

        if(stops > vehicle?.settings?.stops){
            toast.warning(` You cannot add more than ${vehicle?.settings?.stops} stops for this vehicle. Please remove some stops to proceed`)
        }
        else{
            let data = {
                origin: {
                    lat: bookingData?.location_from?.latitude,
                    lng: bookingData?.location_from?.longitude,
                },
                stops: userStop,
                destination: {
                    lat: bookingData?.location_to?.latitude,
                    lng: bookingData?.location_to?.longitude,
                },
                product_id: vehicle?.product_id,
                scheduled_at: moment(bookingData?.pickupdate).format("YYYY-MM-DDThh:mm:ssZ")
            }

            dispatch(getEstimatedPrice(data, vehicle?.product_id))
        }
        
    }


    const selectVehicle = (vehicle) => {
        dispatch({
            type: GETT_SELECTED_VEHICLE,
            payload: vehicle
        })
    }



    return (
            <Grid>
                {products && products.map((vehicle, index) => {

                    let price = 0;

                    if(vehicle?.price){
                        if(vehicle?.price?.tech_fee?.applicable){
                            if(vehicle?.price?.price?.include_tax){
                                price = parseFloat(vehicle?.price?.price?.value) + parseFloat(vehicle?.price?.tech_fee?.value);
                            }
                            else{
                                price = parseFloat(vehicle?.price?.price?.value) + ((parseFloat(vehicle?.price?.price?.value/100)) * 0.25) + parseFloat(vehicle?.price?.tech_fee?.value);
                            }
                        }
                        else{
                            if(vehicle?.price?.price?.include_tax){
                                price = vehicle?.price?.price?.value
                            }
                            else{
                                price = vehicle?.price?.price?.value + ((parseFloat(vehicle?.price?.price?.value)/100) * 0.25);
                            }
                        }
                    }

                    return (
                        <div key={index} style={{ marginTop: 15, marginBottom: 70 }}>
                            <Grid xs={12} md={12} sx={{ flexGrow: 1 }} >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
                                    <Typography className='nameText'>{vehicle?.name}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
                                    <Typography className='classText'>{vehicle?.description}</Typography>
                                </div>
                            </Grid>
                            <Divider />
                            <Stack  flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} borderRadius="20px" justifyContent={"space-between"}>
                                <Grid container spacing={1} alignItems="center" justifyContent={"space-between"} width="98%">
                                    <Grid xs={12} md={4} flexDirection={"column"}>
                                        <Box display={"flex"} flexDirection={{ xs: 'row-reverse', md: 'column' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 10 }}>
                                                <img src={AgegroupImg} style={{ width: 30, height: 30 }} />
                                                <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max. {vehicle?.settings?.riders}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 10 }}>
                                                <img src={BagImg} style={{ width: 30, height: 30 }} />
                                                <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max. {vehicle?.settings?.seats}</Typography>
                                            </div>
                                        </Box>
                                    </Grid>

                                    <Grid xs={12} md={4} mt={5}>
                                        <img src={vehicle?.icon} style={{ width: 400, height: 250 }} />
                                    </Grid>
                                    <Grid xs={12} md={4} >
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                            <ButtonGroup
                                                orientation="vertical"
                                                style={{ borderRadius: '45px' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        color: '#333333',
                                                        backgroundColor: '#F5F5F5',
                                                        height: 100,
                                                        width: 200,
                                                        fontSize: vehicle?.price ? '30px' : '20px',
                                                        fontFamily: 'Tiempos Headline',
                                                        fontWeight: 'bold',
                                                        borderTopRightRadius: 45,
                                                        borderTopLeftRadius: 45
                                                    }}
                                                    onClick={!vehicle?.price ? () => getEstimatedPrice(vehicle) : null}
                                                >
                                                    { vehicle?.price ? `${vehicle?.price?.currency} ${parseFloat(price).toFixed(2)}` : 'Get Price' }
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor: '#333333',
                                                        height: 50,
                                                        width: 200,
                                                        borderBottomLeftRadius: 45,
                                                        borderBottomRightRadius: 45,
                                                        color: vehicle?.price ? '#fff' : 'lightgray'
                                                    }}
                                                    className='buttonText'
                                                    disabled={!vehicle?.price}
                                                    onClick={() => selectVehicle(vehicle)}
                                                >
                                                    SELECT
                                                </Button>

                                            </ButtonGroup>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>
                    )
                })}
            </Grid>
    )
}

export default GetId