import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const BorderBox = ({title, children}) => {
  return (
    <div className='shadow_box' style={{}}>
        <div className='box_title' style={{ }} >
            <Typography fontFamily={"Nunito"} fontWeight="" fontSize={14} color="#fff">{title}</Typography>
        </div>
        {children}
    </div>
  )
}

export default BorderBox