import React, { useState } from 'react'
import '../Dashboard/style.scss'

import { useLocation } from 'react-router-dom';
import InputField from '../../components/InputField'
import { Button, Grid, Box, FormGroup, MenuItem } from '@mui/material'
import Permissions from './Permissions';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import Layout from '../Layout';
import TopBar from '../../components/TopBar';
import Banner from '../../components/Banner';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import BackImg from '../../assets/images/IconsNew/backicon.png'
import SelectInput from '../../components/SelectInput';
import { useNavigate } from "react-router-dom";
function createData(user_name, role, date) {
	return { user_name, role, date };
}

const rows = [
	createData('Sanath', 'Booker', "22/05/2022 10:00 am"),
	createData('Brandon', 'Booker', "15/05/2022 12:00 am"),
	createData('Zane', 'Accountant', "15/05/2022 12:00 am"),

];
const AddRole = ({ head }) => {
	const schema = yup.object({


	}).required();
	const navigate = useNavigate();
	const { register, handleSubmit, setFieldValue, reset, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema)
	});
	const onSubmit = (data) => {


	};
	return (
		<Layout head={head}>
			<Grid container>
				<Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
					{/* <KeyboardArrowLeftOutlinedIcon style={{color:'white',backgroundColor:'#c67b15',borderRadius:'40px'}}/> */}
					<Button
						variant="text"
						color="primary"
						type="submit"
						onClick={() => window.history.back()}
					>
						<img src={BackImg} style={{ width: 30, height: 30 }} />
					</Button>
					<h1 class='underline'><span style={{ textDecorationLine: 'underline', textDecorationColor: '#c67b15' }}>User</span> Management</h1>
				</Grid>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormGroup >
						<Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
							{/* <SelectInput fieldLabel="Role Name" fieldName="role" control={control} error= { errors.role }> 
            <MenuItem value='1'>Accountant</MenuItem>		
            <MenuItem value='2'>Booker</MenuItem> 
            </SelectInput>                       */}
							{/* <InputField fieldLabel="Role Name" fieldName="role" control={control} error= { errors.role }/>                         */}
						</Grid>
						<Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
							{/* <h4 className='underline'><span style={{ textDecorationLine:'underline', textDecorationColor:'#c67b15' }}>Permi</span>ssions</h4> */}
						</Grid>
					</FormGroup>
				</form>
			</Grid>
			<Permissions />
		</Layout>
	)
}

export default AddRole
