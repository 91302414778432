import React, { useState, useEffect } from 'react'
import { Modal, Box } from '@mui/material'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { addTopUp } from '../../Redux/actions/topupActions'
import TopUpForm from '../TopUp/TopUpForm';

const AddTopUp = ({ openModal, closeModal }) => {
    const dispatch = useDispatch();
    const style = {
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '25px',
        border: '1px solid #fff'
    };

    const { user } = useSelector(state => state.auth)
    const schema = yup.object({
        bankID: yup.string().required('Bank Id required'),
        branch_name: yup.string().required('Branch name required'),
        amount: yup.string().required('Amount required'),
        deposit_date: yup.string().required('Deposit Date required'),
        receipt: yup.mixed().required('Receipt required'),
    }).required();

    const { formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

  
    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("userID", user?.parentDetails ?  user?.parentDetails?.id : user?.id);
        formData.append("createdBy", user?.id);
        formData.append("amount", data.amount);
        formData.append("bankID", data.bankID);
        formData.append("branch_name", data.branch_name);
        formData.append("deposit_date", data.deposit_date);
        formData.append("user_type", user?.role);
        formData.append("receipt", data.receipt);
        dispatch(addTopUp(formData))
    };

    return (

        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}

        >
            <Box display={"flex"} width='60%' padding={4} flexDirection="column">
            <div className="topup_box" >
                <TopUpForm heading={true} closeModal={closeModal} />
                </div>
            </Box>
        </Modal >
    )
}

export default AddTopUp
