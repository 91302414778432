import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './Redux/store'
import "typeface-nunito";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GoogleOAuthProvider } from '@react-oauth/google';

const stripePromise = loadStripe('pk_live_51Oaf3D2Y9XSBsSTUZeLfMf9SSHQGoE9l1rwy8i3DZt3109r6lxIbX52B138YpZzsIUR3ZLLKOzch186WTJov88yD007BK7o1pd');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
		<Elements stripe={stripePromise}>
			<App />
		</Elements>
		</GoogleOAuthProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
