import React, { useEffect, useState } from 'react'
import './booking.scss'
import { Button, Grid, Typography, FormGroup, Divider } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import PlacesInput from '../../components/PlacesInput';
import DateInput from '../../components/DateInput';
import SelectPassenger from '../../components/SelectPassenger';
import CountInput from '../../components/CountInput';
import axios from '../../CustomAxios';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import DateTimeInput from '../../components/DateTimeInput';
import { env } from '../../config/Constants';
import GooglePlaceInput from '../../components/GooglePlaceInput';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';

const Hourly = () => {
	const { bookingData } = useSelector(state => state.booking);
	const [open, setOpen] = useState(false)
	const [alignment, setAlignment] = React.useState('oneway');
	const [mylocation, setLocation] = useState(null);
	const [suggList, setSuggList] = useState([])
    const { user } = useSelector(state => state.auth)


	const [checktoday, setcheckday] = useState(bookingData ? moment(bookingData?.pickupdate).isSame(moment(), 'day') : true)

	useEffect(() => {
        searchAddress()
    }, [])

    const searchAddress = async () => {
        await customAxios.get(`search/address/${user?.id}`)
        .then(async response => {

            let sugList = [];
            response?.data?.data?.map((item, index) => {
                let place = JSON.parse(item?.geometry)
                sugList.push({
                    ...place,
                    description: place?.address
                })
            })
            setSuggList(sugList)

        })
        .catch(async error => {

        });

    }


	const handleChange = (event, newAlignment) => {
		setAlignment(newAlignment);
	};

	const navigate = useNavigate();
	const location = useLocation();


	const schema = yup.object({
		location_from: yup.mixed().required("Pick up location required"),
		// location_to: yup.mixed().required("Drop off location required"),
		pickupdate: yup.string().required("Pickup date & time required").nullable(),
		duration: yup.number().required("Duration in hour required").typeError("Duration is required"),
		location_to: mylocation?.address?.includes('Singapore') || bookingData?.location_to === null ? yup.mixed().nullable() : yup.mixed().required("Drop off location required").nullable()

	}
	).required();

	const { handleSubmit, setValue, control, formState: { errors }, getValues, setError } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			distance: bookingData?.distance ? bookingData?.distance : 0,
			duration: bookingData?.duration ? bookingData?.duration : '',
			type: 'hour',
			location_from: bookingData?.location_from,
			location_to: bookingData?.location_to ? bookingData?.location_to : null,
			pickupdate: bookingData?.pickupdate ? new Date(bookingData?.pickupdate) : null,
			passenger: bookingData?.passenger ? bookingData?.passenger : 1,
			adults: bookingData?.adults ? bookingData?.adults : 1,
			childrens: bookingData?.childrens ? bookingData?.childrens : 0,
			minors: bookingData?.minors ? bookingData?.minors : 0,
			baby: bookingData?.baby ? bookingData?.baby : 0,
			durationtext: bookingData?.durationtext,
			durationFull: bookingData?.durationFull
		}
	});



	const getDirections = async () => {
		let data = getValues()
		if (data?.location_from?.address && data?.location_to?.address) {
			await axios.get(`https://maps.googleapis.com/maps/api/directions/json?origin=${data?.location_from?.address}&destination=${data?.location_to?.address}&key=${env === "client" ? process.env.REACT_APP_GOOGLE_KEY_LIVE : process.env.REACT_APP_GOOGLE_KEY_DEV}`)
				.then(response => {
					const { distance, duration } = response.data.routes[0].legs[0]
					setValue("distance", distance);
					setValue("durationtext", `${duration.text}, ${distance.text}`);
					setValue("durationFull", duration);
				})
				.catch(err => {

				})
		}

	}

	const getPasengers = () => {
		setOpen(false)
		let count = getValues();




		setValue("passenger", count.baby + count.adults + count.minors + count.childrens)

	}
	const resetPasengers = () => {
		setOpen(false)
		setValue("adults", 1)
		setValue("minors", 0)
		setValue("childrens", 0)
		setValue("baby", 0)
		setValue("passenger", 1)

	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};



	const onSubmit = (data) => {
		if(moment(data.pickupdate) <= moment()){
			toast.info("Booking date time should be greater than current date time")
			return false;
		}



		if (getValues('location_from')?.address?.includes('Singapore') && parseInt(getValues('duration')) < 3) {
			setError('duration', { type: 'custom', message: 'Minimum 3 Hours Required' })
		} else {
			localStorage.setItem("booking", JSON.stringify(data))
			if (location.pathname.toLowerCase() === "/booking") {

				window.open('/summary', '_blank')
			}
			else {
				navigate('/summary');
			}

		}


	};
	var now = new Date().getTime();

	//set from function
	const setFrom = (place) => {
		setValue("location_from", place)
		setError('location_from', '')
		getDirections()
		setLocation(place);
	}

	//set from function
	const setTo = (place) => {
		setValue("location_to", place)
		setError('location_to', '')
		getDirections()
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0} display={"flex"} alignItems={"center"} marginTop={1} paddingBottom={2}>
				<Grid className='point-pick frm-m' item xs={12} md={!getValues('location_from')?.address.includes('Singapore') ? 6 : 8} sx={{ flexGrow: 1 }} >
					{/* <PlacesInput
						fieldLabel="Pick Up Location"
						fieldName="location_from"
						control={control}
						error={errors.location_from}
						fontSize="14px"
						onPlaceChange={(value) => {
							setValue("location_from", value)
							setError('location_from', '')
							getDirections()
							setLocation(value);
						}}
					/> */}
					<GooglePlaceInput
						control={control}
						fieldName={"location_from"}
						label={'Pick Up Location'}
						setLocation={setFrom}
						error={errors?.location_from?.message}
						suggList={suggList}
					/>
				</Grid>
				{!getValues('location_from')?.address.includes('Singapore') &&
					<Grid className='point-pick drp-loc-h' item xs={12} md={6} sx={{ flexGrow: 1 }} >
						<GooglePlaceInput
							control={control}
							fieldName={"location_to"}
							label={'Drop Off Location'}
							setLocation={setTo}
							error={errors?.location_to?.message}
							suggList={suggList}
						/>
						{/* <PlacesInput
							fieldLabel="Drop Off Location"
							fieldName="location_to"
							control={control}
							error={errors.location_to}
							fontSize="14px"
							onPlaceChange={(value) => {
								setValue("location_to", value)
								setError('location_to', '')
								getDirections()
							}}
						/> */}
					</Grid>}
				<Grid className='point-pick hourly-p-d' item xs={12} md={4} sx={{ flexGrow: 1 }} >
					<DateInput
						todaycheck={checktoday}
						mode={'hour'}
						fieldLabel="Pick Up Date & Time"
						fieldName="pickupdate"
						fieldType="datetime"
						control={control}

						error={errors.pickupdate}
						minDate={moment().toDate()} 
						fontSize="14px"
						changeDate={(value) => setValue("pickupdate", value)}
					/>
				</Grid>
				<Grid className='duration duration-in' item xs={12}  md={getValues('location_from')?.address.includes('Singapore') ? 4 : 3} sx={{ flexGrow: 1 }} >
					<InputField
						fieldName="duration"
						fieldLabel={getValues('location_from')?.address?.includes('Singapore') ? "Duration In Hour(Min 3 Hrs)" : "Duration In Hour"}
						placeholder="Duration In Hour"
						fontSize="14px"
						width='94%'
						control={control}
						readonly={false}
						error={errors.duration}
					/>
				</Grid>
				<Grid className='hourly-psngr' item xs={12} md={3} sx={{ flexGrow: 1 }}>
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
						<SelectPassenger
							fieldLabel="Passenger Details"
							control={control}
							fieldName="passenger"
							fontSize="14px"
							width='100%'
							open={open}
							onClose={handleClose}
							onOpen={handleOpen}
							values={getValues().passenger}
						>
							<CountInput
								fieldLabel="Adults"
								fieldName="adults"
								error={errors.adults}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("adults", value)}
								minValue={1}
								values={getValues().adults}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Baby Seats (0 to 12 Months)"
								fieldName="baby"
								error={errors.baby}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("baby", value)}
								minValue={0}
								values={getValues("baby")}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Child Seats (1 to 2 Yrs)"
								fieldName="childrens"
								error={errors.childrens}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("childrens", value)}
								minValue={0}
								values={getValues("childrens")}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Booster Seats (3 to 4 Yrs)"
								fieldName="minors"
								error={errors.minors}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("minors", value)}
								minValue={0}
								values={getValues("minors")}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center'
								}}
							>
								<Button
									type="button"
									variant="filled"
									onClick={resetPasengers}
									style={{ backgroundColor: '#FFFFFF', borderRadius: 0, height: 'auto', width: 'auto', border: '1px solid #333333' }}>
									<Typography className='resetButton' >RESET</Typography>
								</Button>
								<span style={{ padding: 2 }} />
								<Button
									type="button"
									variant="filled"
									onClick={getPasengers}
									style={{ backgroundColor: '#333333', borderRadius: 0, height: 'auto', width: 'auto' }}>
									<Typography className='buttonText' >APPLY</Typography>
								</Button>
							</div>
						</SelectPassenger>
					</div>
				</Grid>

				<div className='searchbtn-holder hourly-srch' style={{  }}>
					<Button type="submit" className="btn-search hourly-srch" variant="filled" style={{ }}>
					<Typography className='buttonText'>Search</Typography></Button>
				</div>

			</Grid>

		</form>
	)
}

export default Hourly
