import React, { Fragment, useState, useEffect } from 'react'
import './style.scss'
import { Button, Card, Typography, Box } from '@mui/material'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import {useLocation} from 'react-router-dom';
import DeleteImg from '../assets/images/IconsNew/delete.png'
import LocationImg from '../assets/images/IconsNew/Loc1Img.png'
import Location2Img from '../assets/images/IconsNew/Loc2Img.png'
import TimeImg from '../assets/images/IconsNew/calendar.png'
import MainStop from '../screens/Summary/MainStop';
import SubStop from '../screens/Summary/SubStop';
import moment from 'moment';
import Vehicle1Img from '../assets/images/Vehi1.jpg'
import { useSelector } from 'react-redux';
import { Stack } from '@mui/system';
import { Close } from '@mui/icons-material';



const SelectedVehicle = () => {

    const { user } = useSelector(state => state.auth)

    const { selectedBooking: booking, currentVehicle: data, bookingType} = useSelector(state => state.booking)


   

    const renderImage = (data) => {
        if(bookingType === "PAL"){
            return(
                <Grid  
                    sx={{ bgcolor: '#F6F6F6', padding: 2, borderTopLeftRadius: 20, borderTopRightRadius:20 }} padding={2}
                >
                    <div 
                        style={{ 
                            display: 'flex' ,
                            flexDirection:'row' , 
                            justifyContent:'center', 
                            borderTopRightRadius: 20
                        }}
                    >
                        <span>
                            <img src={ data?.vehicledetails?.vehicle_images?.length > 0 ? `${data.imageBasePath}${data?.vehicledetails?.vehicle_images[0]?.path}` : Vehicle1Img} style={{ width: 300, height: 150 }}  />
                        </span>
                    </div>
                    <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center'}}>
                        <span>
                        <Typography className='vehiclename'>{`${data?.vehicledetails?.vehicle_type?.vehicle_type} ${data?.vehicledetails?.veh_details?.brand ? data?.vehicledetails?.veh_details?.brand : ''}`}</Typography>
                        </span>
                    </div>
                    
                </Grid>
                
            )
        }
        else if(bookingType === "MYTRANSFER"){
            return(
                <Grid  
                    sx={{ bgcolor: '#F6F6F6', padding: 2, borderTopLeftRadius: 20, borderTopRightRadius:20 }} padding={2}
                >
                    <div 
                        style={{ 
                            display: 'flex' ,
                            flexDirection:'row' , 
                            justifyContent:'center', 
                            borderTopRightRadius: 20
                        }}
                    >
                        <span>
                            <img src={data?.imageURL} style={{ width: 300, height: 150 }} />
                        </span>
                    </div>
                    <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center'}}>
                        <span>
                        <Typography className='vehiclename'>{`${data?.transportName}`}</Typography>
                        </span>
                    </div>
                    
                </Grid>
            )
        }
    }

    const pricingDetails = (data) => {
        if(bookingType === "PAL"){
            return(
                <Grid sx={{ bgcolor: '#F6F6F6', height: 'auto', flex: 0.3, paddingLeft: 3}}>
                    <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography style={{
                            padding: 5,
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: 10, 
                            flex: 0.5 
                        }}>Base Price</Typography>
                        <Typography style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontSize: 14,
                            fontWeight: 'bold',
                            paddingRight: 10, 
                            flex: 0.5  }} >S$ {parseFloat(data?.price + data?.adminMarkup).toFixed(2)}</Typography>
                    </div>
                    {user && user.role === "agent" &&  <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography  style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: 10, 
                            flex: 0.5 }}>Markup Price</Typography>
                        <Typography style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontSize: 14,
                            fontWeight: 'bold',
                            paddingRight: 10, 
                            flex: 0.5  }} >S$ {parseFloat(data?.agentmarkup).toFixed(2)}</Typography>
                    </div>}
                    {data?.extraCharges?.map(extra => (
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}} key={extra?.id}>
                            <Typography  style={{
                                padding: 5,
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                paddingRight: 10, 
                                flex: 0.5
                            }}>{extra?.name}</Typography>
                            <Typography style={{ padding: 5,
                                fontFamily: 'Nunito',
                                fontSize: 14,
                                fontWeight: 'bold',
                                paddingRight: 10, 
                                flex: 0.5  }}  >S$ {extra?.price?.toFixed(2)}</Typography>
                        </div>
                    ))}
                    {data?.services?.map(extra => (
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}} key={extra?.id}>
                            <Typography  style={{
                                padding: 5,
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                paddingRight: 10, 
                                flex: 0.5
                            }}>{extra?.name}</Typography>
                            <Typography style={{ padding: 5,
                                fontFamily: 'Nunito',
                                fontSize: 14,
                                fontWeight: 'bold',
                                paddingRight: 10, 
                                flex: 0.5  }}  >S$ {parseFloat(extra?.price)?.toFixed(2)}</Typography>
                        </div>
                    ))}
                    {data?.toll !=0 && <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography  style={{
                            padding: 5,
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: 10, 
                            flex: 0.5
                        }}>{"Toll Rate"}</Typography>
                        <Typography style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontSize: 14,
                            fontWeight: 'bold',
                            paddingRight: 10, 
                            flex: 0.5  }}  >S$ {data?.toll?.toFixed(2)}</Typography>
                    </div>}
                    <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography  style={{
                            padding: 5,
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: 10, 
                            flex: 0.5
                        }}>Tax</Typography>
                        <Typography style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontSize: 14,
                            fontWeight: 'bold',
                            paddingRight: 10, 
                            flex: 0.5  }}  >S$ {data?.taxValue.toFixed(2)}</Typography>
                    </div>
                    
                    <Divider orientation="horizontal" sx={{ bgcolor: "#F6F6F6",height:'0px',width:'100%',marginTop:2 }} flexItem/>
        
                    <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography style={{ color: '#333333', fontWeight: 'bold', paddingRight: 10, flex: 0.5 }} >Total</Typography>
                        <Typography style={{ color: '#333333', fontWeight: 'bold', fontSize: 18, flex: 0.5 }} >S$ {parseFloat(data?.total).toFixed(2)}</Typography>
                    </div>
                </Grid>
            )
        }
        else if(bookingType === "MYTRANSFER"){
            return(
                <Grid sx={{ bgcolor: '#F6F6F6', height: 'auto', flex: 0.3, paddingLeft: 3}}>
                    <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography style={{
                            padding: 5,
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: 10, 
                            flex: 0.5 
                        }}>Base Price</Typography>
                        <Typography style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontSize: 14,
                            fontWeight: 'bold',
                            paddingRight: 10, 
                            flex: 0.5  }} >S$ {parseFloat(data?.price + data?.adminMarkup).toFixed(2)}</Typography>
                    </div>
                    {user && user.role === "agent"  && <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography  style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: 10, 
                            flex: 0.5 }}>Markup Price</Typography>
                        <Typography style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontSize: 14,
                            fontWeight: 'bold',
                            paddingRight: 10, 
                            flex: 0.5  }} >S$ {parseFloat(data?.agentmarkup).toFixed(2)}</Typography>
                    </div>}
                    <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography  style={{
                            padding: 5,
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: 10, 
                            flex: 0.5
                        }}>Tax</Typography>
                        <Typography style={{ padding: 5,
                            fontFamily: 'Nunito',
                            fontSize: 14,
                            fontWeight: 'bold',
                            paddingRight: 10, 
                            flex: 0.5  }}  >S$ {parseFloat(data?.taxValue).toFixed(2)}</Typography>
                    </div>
                    <Divider orientation="horizontal" sx={{ bgcolor: "#F6F6F6",height:'0px',width:'100%',marginTop:2 }} flexItem/>
        
                    <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography style={{ color: '#333333', fontWeight: 'bold', paddingRight: 10, flex: 0.5 }} >Total</Typography>
                        <Typography style={{ color: '#333333', fontWeight: 'bold', fontSize: 18, flex: 0.5 }} >S$ {parseFloat(data?.total).toFixed(2)}</Typography>
                    </div>
                </Grid>
            )
        }
    }
    

    const location = useLocation();
    return (
        
            <div>
                {/* <Button variant="contained" endIcon={<img src={DeleteImg} style={{width:20,height:20}} />}
                        style={{color:'#FFFFFF',backgroundColor:'#333333',borderTopLeftRadius:15,borderTopRightRadius:15,height:40,width:'auto',
                        position: 'absolute', cursor: 'pointer',fontFamily:'Nunito',fontWeight:'bold',fontSize:'16px',marginLeft:'2.5%',display:'flex'}}>
                            Trip ID : #21343
                        </Button> */}
                <Stack flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" bgcolor={"#F6F6F6"} className="tripsdiv" >
                    
                    <Grid sx={{ bgcolor: '#F6F6F6', padding: 2, borderTopLeftRadius: 20, borderTopRightRadius:20 }} >
                                         
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start', paddingBottom: 10}}>
                             <span>
                            <img src={TimeImg} style={{ width: 30, height: 30,paddingRight:10  }} />
                            </span>
                            <span>
                            <Typography className='loctextbold'>{moment(booking?.pickupdate).local().format("DD-MM-YYYY hh:mm A") }</Typography>
                            </span>
                        </div>
                        <MainStop 
                            address={booking?.location_from?.address} 
                            count={1} 
                            color="#FF0000" 
                        />
                        <Divider orientation="vertical" flexItem sx={{ width:'0px',height: 'auto',marginLeft:2.3,marginTop:-3,marginBottom:-1 ,paddingBottom:2,minHeight:20,borderRight:'2px dashed' ,borderLeft:'none',borderRightColor: "#c67b15",}} >
                        {booking?.stops?.map((stop, index) => (
                            <SubStop 
                                address={stop?.stop_name?.address} 
                                count={index + 2} 
                                color="blue"
                                index={index}
                            />
                        ))}
                        </Divider>
                        <MainStop 
                            address={booking?.location_to?.address} 
                            count={booking?.stops ? booking?.stops?.length+2 : 2} 
                            color="#0066FF"
                        />
                    </Grid>
                    
                    {renderImage(data)}
                    </Stack>
                    <Divider  color='#c67b15' height='2px' flexItem sx={{ borderWidth: 1 }} />
                    <Stack flexDirection={{ xs: 'column', md: "row"}} flex={1} justifyContent="space-between" borderRadius={20}>
                    <Grid  sx={{ bgcolor: '#F6F6F6', height: 'auto', flexGrow:1, paddingLeft: 3 }} flex={0.7} >
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <Typography className='totalText'> Total Fare</Typography>
                            </span>
                        </div>
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            
                            <span>
                            <Typography className='loctext' >All prices are in SGD</Typography>
                            </span>
                        </div>
                        
                    </Grid>
                    {pricingDetails(data)}
                    
                </Stack>
                
        </div>
        
    )
}

export default SelectedVehicle
