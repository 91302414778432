import React from 'react'
import Banner from '../../components/Banner'
import Header from '../../components/Header';
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import B2BForm from './B2BForm';
import Footer from '../../components/Footer';
import { Button, Grid, Typography, Stack } from '@mui/material'


const B2BRegistration = () => {

    return (
        <div>
            
            <B2BForm/>   
           
        </div>            
    )
}

export default B2BRegistration
